import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'

import { CardDashboad } from '../components'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { DataBenefitsOrRewards } from '../types';
import { COLORS } from 'styles';

const useStyles = makeStyles({

    title: {
        color: COLORS.BASE,
    },
    divider: {
        backgroundColor: COLORS.BASE
    }

});

interface propsBenefitsOrRewards {
    dataBenefits: DataBenefitsOrRewards,
    dataRewards: DataBenefitsOrRewards
}

const CouponsCard: React.FC<propsBenefitsOrRewards> = ({ dataBenefits, dataRewards }) => {
    const classes = useStyles()

    return (
        <>
            <Grid item xs={6}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Beneficios
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Rewards
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataBenefits.used} description='Beneficios Usados' value_previous={dataBenefits.used_previous} isPercent={false} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataBenefits.avg} description='Beneficios Promedio' value_previous={dataBenefits.avg_previous} isPercent={true} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataRewards.used} description='Rewards Usados' value_previous={dataRewards.used_previous} isPercent={false} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataRewards.avg} description='Rewards promedio' value_previous={dataRewards.avg_previous} isPercent={false} />
            </Grid>
        </>
    )
}

export { CouponsCard }

