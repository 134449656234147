import React from 'react'

import { useApiCall } from 'hooks'
import { Dialog, snackbar } from 'components'
import { ERRORS } from 'consts'

import { Notification } from '../types'
import { deleteNotification } from '../api'

interface Props {
  handleClose: () => void
  notification: Notification | null
  onDone: () => void
}

const DeleteNotificationDialog: React.FC<Props> = ({ handleClose, notification, onDone }) => {
  const [deleteNotificationApi, isLoading] = useApiCall<number, void>(deleteNotification)

  const onDelete = async () => {
    if (!notification) return
    try {
      await deleteNotificationApi(notification.id)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title={notification!.sent ? `No es posible eliminar una notificación ya enviada` : `¿Está seguro que desea eliminar la notificación ${notification?.title}?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      onAccept={onDelete}
      okButtonText="Eliminiar"
      okButtonProps={{ disabled: notification!.sent || isLoading }}
    />
  )
}

export { DeleteNotificationDialog }
