import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DOMPurify from 'dompurify'

import { ERRORS } from 'consts'
import { useApi } from 'hooks'
import { snackbar, Dialog } from 'components'

import { getMembershipsPrizes } from './api'
import { DefaultTable } from '../../components'
//import { GetPrizesResponse, Prize } from './types'
import { GetPrizesResponse, Prize } from '../../Prizes/types'
import { CreatePrizeDialog, DeletePrizeDialog, UpdatePrizeDialog } from './components'
import { REWARD_TYPE_BY_MEMBERSHIP } from './components/constants'

const columns = [
  { title: 'ID', field: 'id' },
  {
    title: 'Título',
    field: 'title',
    render: (row: { title: string }) => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.title) }} />
  },
  {
    title: 'Descripción',
    field: 'shortTitle',
  },
  {
    title: 'Texto adicional',
    field: 'additionalText',
  },
  {
    title: 'Tipo',
    render: (row: Prize) => (REWARD_TYPE_BY_MEMBERSHIP.find(value => value.id === row.rewardType)?.description),
    sorting: false,
  },
]

const getPrizeResponseGetter = (responseData: GetPrizesResponse) => responseData?.prizes ?? []

interface UpdateTagDialogProps {
  handleClose: () => void
  membershipId: number
}

const MembershipsPrizes: React.FC<UpdateTagDialogProps> = ({
  handleClose,
  membershipId
}) => {
  const [{ data: prizes, isLoading }, , setPrizes] = useApi<GetPrizesResponse, Prize[]>(

    () => getMembershipsPrizes(membershipId),
    getPrizeResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )
  const [isCreatePrizeDialogOpen, setIsCreatePrizeDialogOpen] = useState(false)
  const [isDeletePrizeDialogOpen, setIsDeletePrizeDialogOpen] = useState(false)
  const [prizeToDelete, setPrizeToDelete] = useState<Prize | null>(null)
  const [isUpdatePrizeDialogOpen, setIsUpdatePrizeDialogOpen] = useState(false)
  const [prizeToUpdate, setPrizeToUpdate] = useState<Prize | null>(null)


  const handleCreatePrizeDone = (newPrize: Prize) => {
    setPrizes([
      ...prizes
      , newPrize]
    )
    setIsCreatePrizeDialogOpen(false)
  }

  const handleDeletePrizeDialogClose = () => {
    setIsDeletePrizeDialogOpen(false)
    setPrizeToDelete(null)
  }

  const handleDeletePrizeDone = () => {
    if (!prizeToDelete) return
    setPrizes(prizes.filter((prize: Prize) => prize.id !== prizeToDelete.id))
    handleDeletePrizeDialogClose()
  }

  const handleUpdatePrizeDialogClose = () => {
    setIsUpdatePrizeDialogOpen(false)
    setPrizeToUpdate(null)
  }

  const handleUpdatePrizeDone = (updatedPrize: Prize) => {
    setPrizes(
      prizes.map((prize: Prize) => {
        if (prize.id === updatedPrize.id) {
          return updatedPrize
        }
        return prize
      }),
    )
    handleUpdatePrizeDialogClose()
  }


  const actions = [
    {
      icon: 'add',
      tooltip: 'Nuevo premio',
      isFreeAction: true,
      onClick: () => setIsCreatePrizeDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar premio',
      onClick: (_: React.SyntheticEvent, row: Prize | Prize[]) => {
        setPrizeToUpdate(row as Prize)
        setIsUpdatePrizeDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: `Eliminar`,
      onClick: (_: React.SyntheticEvent, row: Prize | Prize[]) => {
        setPrizeToDelete(row as Prize)
        setIsDeletePrizeDialogOpen(true)
      },
    },
  ]

  return (
    <Dialog
      title="Premios asignados"
      isOpen
      showActions
      onCancel={handleClose}
      cancelButtonText=""
      isLoading={isLoading}
      okButtonText="Cerrar"
      onAccept={handleClose}
      style={{ minWidth: '40%' }}
    >
      <DefaultTable
        title=""
        columns={columns}
        data={prizes}
        isLoading={isLoading}
        actions={actions}
        options={{
          pageSize: 5
        }}
      />
      {isCreatePrizeDialogOpen && (
        <CreatePrizeDialog
          handleClose={() => setIsCreatePrizeDialogOpen(false)}
          onDone={handleCreatePrizeDone}
          membershipId={membershipId}
        />
      )}
      {isDeletePrizeDialogOpen && prizeToDelete && (
        <DeletePrizeDialog
          handleClose={handleDeletePrizeDialogClose}
          prizeId={prizeToDelete.id}
          prizeTitle={'Eliminar premio'}
          onDone={handleDeletePrizeDone}
        />
      )}
      {isUpdatePrizeDialogOpen && prizeToUpdate && (
        <UpdatePrizeDialog
          handleClose={handleUpdatePrizeDialogClose}
          prizeToUpdate={prizeToUpdate}
          onDone={handleUpdatePrizeDone}
        />
      )}
    </Dialog>
  )
}

export { MembershipsPrizes }
