import { AxiosRequestConfig } from 'axios'

import { ParametersDownload } from './types'

const getUserStatus = ({
  startDate,
  endDate
}: ParametersDownload): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'dashboard/users-status',
    method: 'GET',
    params,
  }
}

const getUserFrequency = ({
  startDate,
  endDate
}: ParametersDownload): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'dashboard/users-frequency',
    method: 'GET',
    params,
  }
}

const getUserByLevel = ({
  startDate,
  endDate
}: ParametersDownload): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'dashboard/users-by-level',
    method: 'GET',
    params,
  }
}

const getUserTest = ({
  startDate,
  endDate
}: ParametersDownload): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'dashboard/users-test',
    method: 'GET',
    params,
  }
}

const getAssetsByPeriodByStateVariation = (): AxiosRequestConfig => {
  return {
    url: 'dashboard/assets_by_month/state_variation',
    method: 'GET'
  }
}

const getAssetsByPeriodByFrecuencyVariation = (): AxiosRequestConfig => {
  return {
    url: 'dashboard/assets_by_month/frequency_variation',
    method: 'GET'
  }
}

const getAssetsByPeriodByStatusAverage = (): AxiosRequestConfig => {
  return {
    url: 'dashboard/assets_by_month/state_average',
    method: 'GET'
  }
}

const getAssetsByPeriodByFrequencyAverage = (): AxiosRequestConfig => {
  return {
    url: 'dashboard/assets_by_month/frequency_average',
    method: 'GET'
  }
}

export { getUserStatus, getUserFrequency, getUserByLevel, getUserTest, getAssetsByPeriodByStateVariation, getAssetsByPeriodByFrecuencyVariation, getAssetsByPeriodByStatusAverage, getAssetsByPeriodByFrequencyAverage }
