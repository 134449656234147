import { Card, CardContent, Typography, Button, makeStyles, Grid } from '@material-ui/core'
import { UploadButton } from 'components'
import React, { useState } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    width: 255,
    height: 257,
    border: '2px dashed #ccc',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  imagePreview: {
    maxHeight: '73%',
    maxWidth: '100%',
  },
  uploadIcon: {
    fontSize: 48,
    color: '#ccc',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 18,
    color: '#757575',
    marginBottom: theme.spacing(2),
  },
}))

interface ImageUploaderProps {
  label: string
  preview?: string
  onChange: (file: File) => void
  onDelete?: () => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ label, preview, onChange, onDelete }) => {
  const classes = useStyles()

  const [image, setImage] = useState<string | undefined>(undefined)

  const handleImageChange = (file: File) => {
    setImage(URL.createObjectURL(file))
    onChange(file)
  }

  const handleDelete = () => {
    setImage(undefined)
    if (onDelete) onDelete()
  }

  return (
    <>
      <Grid className={classes.cardContainer}>
        <span className={classes.label}>{label}</span>
        <Card className={classes.card}>
          <CardContent>
            {preview ? (
              <>
                <img src={preview} alt={label} className={classes.imagePreview} />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                >
                  Borrar
                </Button>
              </>
            ) : (
              <>
                {<CloudUploadIcon className={classes.uploadIcon} />}
                <Typography variant="body2" color="textSecondary" component="p">
                  Busca una imagen en tu equipo.
                </Typography>
                <div className={classes.button}>
                  <UploadButton
                    id={`logo-upload-button-${label}`}
                    accept="image/*"
                    label="BUSCAR"
                    onChange={handleImageChange}
                    withoutStartIcon={true}
                  />
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export { ImageUploader }
