import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'
import { ERRORS } from 'consts'

import { updateMembershipPrizesOrder } from '../api'
import { Membership, UpdateMembershipPrizeOrderData } from '../types'

interface CreateMembershipsDialogProps {
  membership: Membership
  handleClose: () => void
  onDone: (newMembership: Membership) => void
}

const OrderMembershipPrizesDialog: React.FC<CreateMembershipsDialogProps> = ({
  membership,
  handleClose,
  onDone,
}) => {
  const [prizes, setPrizes] = useState(membership.prizes)

  const [updateMembershipApi, isLoading] = useApiCall<UpdateMembershipPrizeOrderData, void>(
    updateMembershipPrizesOrder,
  )

  useEffect(() => {
    // Ordena los premios por el campo 'order' en orden ascendente al cargar el componente
    if (prizes) {
      const sortedPrizes = [...prizes].sort((a, b) => a.order - b.order);
      setPrizes(sortedPrizes);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      if (!prizes) return
      await updateMembershipApi({ id: membership.id, prizes })
      onDone({
        ...membership,
        prizes,
      })
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const grid = 8;

  const handleOnDragEnd = (result: any) => {
    if (!result.destination || !prizes) return;

    const items = Array.from(prizes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPrizes(items);
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#e78923" : "grey",
    ...draggableStyle
  });

  return (
    <Dialog
      title="Ordenar premios"
      isOpen
      showActions
      onCancel={handleClose}
      okButtonText="Guardar"
      onAccept={handleSubmit}
      contentStyle={{ minWidth: 500 }}
    >
      <Grid container spacing={3} justify="center" alignItems="center">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                {prizes && prizes.map(({ id, title }, index) => {
                  return (
                    <Draggable key={id} draggableId={`prize_${id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <p>{index + 1}. {title}</p>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Dialog>
  )
}

export { OrderMembershipPrizesDialog }
