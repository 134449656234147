import { Grid, Button, Divider } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ColorPicker } from '../ColorPicker'
import { ReactComponent as ResetIcon } from 'assets/images/reset-icon.svg'
import { useStyles } from '../styles'
import { AppColors, Style } from '../types'
import { APP_COLORS } from 'styles/appColors'
import { ImageUploader } from '../ImageUploader'

interface AppMobileProps {
  style: Style
  setStyle: (style: Style) => void
  setLogoFile: (file: File | undefined) => void
  setHomeFile: (file: File | undefined) => void
  onSave: () => void
}

const AppMobile: React.FC<AppMobileProps> = ({ style, setStyle, setLogoFile, setHomeFile, onSave }) => {
  const classes = useStyles()

  const [primaryBackground, setPrimaryBackground] = useState<string>('')
  const [secondaryBackground, setSecondaryBackground] = useState<string>('')
  const [logoPreview, setLogoPreview] = useState<string>('')
  const [homePreview, setHomePreview] = useState<string>('')

  useEffect(() => {
    const appColors: AppColors = style.appColors
    setPrimaryBackground(appColors.$PRIMARY_BACKGROUND)
    setSecondaryBackground(appColors.$SECONDARY_BACKGROUND)
    setLogoPreview(style.appLogoUrl)
    setHomePreview(style.homeUrl)
  }, [style])

  const reloadStyle = () => setStyle(style)

  useEffect(() => {
    style.appColors = {
      $PRIMARY_BACKGROUND: primaryBackground,
      $SECONDARY_BACKGROUND: secondaryBackground,
    }
    reloadStyle()
  }, [style.appColors])

  const handlePrimaryBackgroundChange = (color: { hex: string }) => {
    setPrimaryBackground(color.hex)
  }

  const handleSecondaryBackgroundChange = (color: { hex: string }) => {
    setSecondaryBackground(color.hex)
  }

  const handleLogoChange = (file: File) => {
    setLogoPreview(URL.createObjectURL(file))
    setLogoFile(file)
  }

  const handleLogoDelete = () => {
    setLogoPreview('')
    setLogoFile(undefined)
  }

  const handleHomeChange = (file: File) => {
    setHomePreview(URL.createObjectURL(file))
    setHomeFile(file)
  }

  const handleHomeDelete = () => {
    setHomePreview('')
    setHomeFile(undefined)
  }

  const reset = async () => {
    style.appColors = {
      $PRIMARY_BACKGROUND: APP_COLORS.$PRIMARY_BACKGROUND,
      $SECONDARY_BACKGROUND: APP_COLORS.$SECONDARY_BACKGROUND,
    }
    await onSave()
  }

  return (
    <>
      <Grid container alignItems="flex-end" className={classes.dividerContainer}>
        <span className={classes.dividerText}>COLOR</span>
        <Button
          onClick={reset}
          variant="outlined"
          color="primary"
          className={classes.resetButton}
          startIcon={<ResetIcon height={20} width={20} className={classes.resetIcon} />}
        >
          Resetear
        </Button>
      </Grid>
      <Divider className={classes.divider} variant="fullWidth" />
      <Grid container item xs={12} style={{ marginBottom: 30 }}>
        <ColorPicker label="Primario" color={primaryBackground} handler={handlePrimaryBackgroundChange} />
        <ColorPicker label="Secundario" color={secondaryBackground} handler={handleSecondaryBackgroundChange} />
      </Grid>
      <Grid container alignItems="flex-end" className={classes.dividerContainer}>
        <span className={classes.dividerText}>IMAGEN</span>
      </Grid>
      <Divider className={classes.divider} variant="fullWidth" />
      <Grid container item xs={12} style={{ marginBottom: 30 }}>
        <ImageUploader
          label="Logo"
          preview={logoPreview}
          onChange={handleLogoChange}
          onDelete={handleLogoDelete}
        />
        <ImageUploader
          label="Home"
          preview={homePreview}
          onChange={handleHomeChange}
          onDelete={handleHomeDelete}
        />
      </Grid>
    </>
  )
}

export { AppMobile }