import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'styles'

const useStyles = makeStyles(() => ({
  areaChartCustomTooltip: {
    backgroundColor: "white",
    border: "1px solid #ccc",
    padding: "10px",
    lineHeight: 1.2,
  },
  chartBody: {
    alignItems: "center",
    background: COLORS.WHITE,
    /* border: 1px solid black; */
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
  },
}))

export { useStyles }
