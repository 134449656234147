const PRIZE = {
  REWARD_TYPE: {
    'AUTOMATIC': 'A',
    'MANUAL': 'M',
    'ALWAYS_ON': 'O',
    'SPECIAL': 'S',
  }
}

export { PRIZE }
