import { AxiosRequestConfig } from 'axios'

import { CreateMembershipPayload, UpdateMembershipData, UpdateMembershipPrizeOrderData } from './types'
import { Prize } from '../Prizes/types'

const getMemberships = (): AxiosRequestConfig => ({
  url: '/memberships',
  method: 'GET',
})

const createMembership = (Membership: CreateMembershipPayload): AxiosRequestConfig => {

  const payload = new FormData()
  const { title, amountVisits,pushTitle,pushDescription,pushMessage } = Membership

  payload.append('title', title)
  payload.append('amountVisits', amountVisits)
  if (pushTitle) payload.append('pushTitle', pushTitle)
  if (pushDescription) payload.append('pushDescription', pushDescription)
  if (pushMessage)  payload.append('pushMessage', pushMessage.toString())

  return {
    url: '/memberships',
    method: 'POST',
    data: payload,
  }
}

const updateMembership = (Membership: UpdateMembershipData): AxiosRequestConfig => {

  const payload = new FormData()
  const { title, amountVisits,pushTitle,pushDescription,pushMessage } = Membership.payload

  payload.append('title', title)
  payload.append('amountVisits', amountVisits)
  if (pushTitle) payload.append('pushTitle', pushTitle)
  if (pushDescription) payload.append('pushDescription', pushDescription)
  if (pushMessage!==undefined)   payload.append('pushMessage', pushMessage.toString())

  return {
    url: `/memberships/${Membership.id}`,
    method: 'PUT',
    data: payload,
  }
}

const updateMembershipPrizesOrder = (data: UpdateMembershipPrizeOrderData): AxiosRequestConfig => {
  const { id, prizes } = data;
  return {
    url: `/memberships/${id}/order`,
    method: 'PUT',
    data: {
      prizes: prizes.map(el => el.id)
    },
  }
}

const deleteMembership = (id: number): AxiosRequestConfig => ({
  url: `/memberships/${id}`,
  method: 'DELETE',
})

export { getMemberships, createMembership, updateMembership, deleteMembership, updateMembershipPrizesOrder }
