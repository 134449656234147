import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { PieChart, Pie, Cell, Legend } from "recharts";

const useStyles = makeStyles(() => ({
  pieChart: {
    margin: 'auto',
    width: 400,
    height: 400
  }
}));

interface ChartProps {
  data: any
}

const COLORS = ['#5b71d7','#00C49F','#FFBB28','#f08080','#90ee90','#d3d3d3','#ffb6c1','#ffa07a','#20b2aa','#87cefa'];

function PieChartComponent(props:ChartProps) {
  const classes = useStyles();

  return (
    <Paper>
      <PieChart width={400} height={400} className={classes.pieChart}>
        <Legend layout="horizontal" verticalAlign="top" align="center" />
        <Pie
          data={props.data}
          dataKey="totalUsers"
          nameKey={"answer"}
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          label
        >
          {props.data.map((entry:any, index:number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </Paper>
  );
}

export { PieChartComponent };
