import React, { useState } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { Typography } from '@material-ui/core';

const COLORS = ["#e78923", "#2b5b53", "#bc9878", '#457b9d', '#a8dadc', '#f4a261'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index, value
}: any) => {
  // const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={COLORS[index % COLORS.length]} fill="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey}
      fill={COLORS[index % COLORS.length]}
      textAnchor={textAnchor}
      dominantBaseline="central"
    >
      {value} {' (' + `${(percent * 100).toFixed(0)}%` + ')'}
    </text>
    </>
  );
};

interface propsUsersStatus {
  data: any[]
  title: string
  widthParam?: number
  cx?: number
}

const CustomPieChart: React.FC<propsUsersStatus> = ({ data, title, widthParam, cx }) => {
  const [customWidth] = useState(widthParam ?? 390)
  const [customCx] = useState(cx ?? 100)
  return (
    <>
      <Typography variant="h6" component="h6" style={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <PieChart width={customWidth} height={300} margin={{ left: 100 }} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Pie
          data={data}
          cx={customCx}
          cy={100}
          label={renderCustomizedLabel}
          outerRadius={60}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend align="left"/>
        <Tooltip />
      </PieChart>
    </>
  );
}
export { CustomPieChart }