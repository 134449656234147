import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, snackbar, UploadButton } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS } from 'consts'
import { useStyles } from './styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { updateMembership } from '../api'
import { UpdateMembershipData, Membership } from '../types'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  amountVisits: [
    {
      validate: (value: string) => ((value && Number(value) >= 0 && Number(value) < 100) || !value) && Number.isInteger(Number(value)),
      message: 'La cantidad debe ser mayor a 0 y menor a 100',
    },
  ],
  pushTitle: [
    {
      validate: (title: string) => title.length <= 70,
      message: 'No puede superar los 70 caracteres',
    }
  ],
  pushDescription: [
    {
      validate: (shortTitle: string) => shortTitle.length <= 120,
      message: 'No puede superar los 120 caracteres',
    },
  ],
}

interface CreateMembershipsDialogProps {
  membership: Membership
  handleClose: () => void
  onDone: (newMembership: Membership) => void
  amountVisitsUsed: number[]
}

const UpdateMembershipDialog: React.FC<CreateMembershipsDialogProps> = ({ membership, handleClose, onDone, amountVisitsUsed }) => {
  const classes = useStyles()

  const [title, setTitle] = useState(membership.title)
  const [level, setLevel] = useState(membership.level)
  const [amountVisits, setAmountVisits] = useState(membership.amountVisits)
  const [pushTitle, setPushTitle] = useState(membership.pushTitle ? membership.pushTitle : '');
  const [pushDescription, setPushDescription] = useState(membership.pushDescription ? membership.pushDescription : '');
  const [pushMessage, setPushMessage] = useState(membership.pushMessage);

  const data = { title, amountVisits, pushTitle, pushDescription, pushMessage }
  const requiredFields = ['title', 'shortTitle', 'additionalText', ...pushMessage ? ['pushTitle', 'pushDescription'] : []]
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [updateMembershipApi, isLoading] = useApiCall<UpdateMembershipData, void>(updateMembership)

  const handleSubmit = async () => {
    try {
      await updateMembershipApi({ id: membership.id, payload: data })
      onDone({
        id: membership.id,
        title,
        amountVisits,
        level,
        pushTitle, pushDescription, pushMessage
      })
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleChangePushTitle: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushTitle(e.target.value)
  }

  const handleChangepushDescription: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushDescription(e.target.value)
  }

  const handleChangePushMessage: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushMessage(!pushMessage);
  }
  return (
    <Dialog
      title="Editar Membresia"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar membresia"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={amountVisits}
            onChange={e => setAmountVisits(e.target.value)}
            label="Visitas"
            type="number"
            fullWidth
            error={errors.amountVisits.hasError}
            helperText={errors.amountVisits.message}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={true}
            value={level}
            label="Nivel"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangePushMessage} checked={pushMessage} />} label="Activar push" />
        </Grid>
        {pushMessage && (
          <>
            <Grid item xs={11}>
              <TextField
                label="Título push"
                value={pushTitle}
                fullWidth
                onChange={handleChangePushTitle}
                disabled={isLoading}
                required
                error={errors.pushTitle.hasError}
                helperText={errors.pushTitle.message}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Descripción push"
                value={pushDescription}
                fullWidth
                onChange={handleChangepushDescription}
                disabled={isLoading}
                required
                error={errors.pushDescription.hasError}
                helperText={errors.pushDescription.message}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

export { UpdateMembershipDialog }

