import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import { Visits } from './Visits'
import { Users } from './Users'
import { CouponsCard, CouponsTopFive } from './Benefits'
import { RewardsTopFive } from './Rewards'
import { Charts } from './Charts/index'
import { DatePicker } from '@material-ui/pickers'
import { Moment } from 'moment'
import { ERRORS, STRINGS } from 'consts'
import { Container, Button, CircularProgress, Typography, Select, MenuItem } from '@material-ui/core'
import { useApi, useApiCall } from 'hooks'
import { downloadData } from './api'

import { snackbar } from 'components'
import { DataResponse, DataParams, DataCards } from './types'
import moment from 'moment'
import { StoresTopFive } from './Stores'
import { GetStoresResponse, Store } from '../Stores/types'
import { getStores } from '../Stores/api'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const dataSelect = { 12: 'Últimos 30 días', 13: 'Últimos 7 días' }

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface Items {
  [key: string]: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{width: `100%`}}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const dataTest: DataCards = {
  visits: { amount: 0, avg: 0, unique: 0, amount_previous: 0, avg_previous: 0, unique_previous: 0 },
  benefits: { used: 0, avg: 0, used_previous: 0, avg_previous: 0 },
  rewards: { used: 0, avg: 0, used_previous: 0, avg_previous: 0 },
  users: { active: 0, created: 0, active_previous: 0, created_previous: 0 },
  ga_reports: { current: { active1DayUsers: 0, active28DayUsers: 0, active7DayUsers: 0 }, previous: { active1DayUsers: 0, active28DayUsers: 0, active7DayUsers: 0 } }
}

const Dashboard: React.FC = () => {
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, "days").startOf('day'))
  const [endDate, setEndDate] = useState<Moment>(moment().subtract(1, "days").endOf('day'))
  const [dataCards, setDataCards] = useState<DataCards>(dataTest)
  const [periodNumber, setPeriodNumber] = useState<number>(13)
  const [isMonth, setIsMonth] = useState(false)
  const [storeSelected, setStoreSelected] = useState<number>(0)
  const [value, setValue] = React.useState(0);

  const getStoresResponseGetter = (responseData: GetStoresResponse) => responseData?.stores ?? []

  const [{ data: stores }] = useApi<GetStoresResponse, Store[]>(
    getStores,
    getStoresResponseGetter,
    {
      baseData: [],
      onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
    },
  )

  const setPeriod = async (value: number) => {
    const current = moment();
    dataCards.period = undefined

    if (value < 12) {
      current.set('month', value)
      setStartDate(current.clone().startOf('month').startOf('day'))
      setEndDate(current.clone().endOf('month').endOf('day'))
      setIsMonth(true)
    }

    if (value == 12) {
      const thirtyDatesPreviou = current.clone().subtract(30, "days")
      setStartDate(thirtyDatesPreviou.startOf('day'))
      setEndDate(current.endOf('day').subtract(1, "days"))
      setIsMonth(false)
    }

    if (value == 13) {
      const sevenDatesPreviou = current.clone().subtract(7, "days")
      setStartDate(sevenDatesPreviou.startOf('day'))
      setEndDate(current.endOf('day').subtract(1, "days"))
      setIsMonth(false)
    }

  }

  const handlePeriodSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriodNumber(event.target.value as number)
    setPeriod(event.target.value as number)
  }

  const handleStartDateChange = (newDate: Moment | null) => {
    if (newDate) {
      setStartDate(newDate.startOf('day'))
      setIsMonth(false)
    }
  }

  const handleEndDateChange = (newDate: Moment | null) => {
    if (newDate) {
      setEndDate(newDate.endOf('day'))
      setIsMonth(false)
    }
  }

  const handleStoreSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStoreSelected(event.target.value as number)
  }

  const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(downloadData)

  const handleDownload = async () => {
    try {
      const data = await requestData({
        storeId: storeSelected,
        startDate: startDate?.toISOString(true),
        endDate: endDate?.toISOString(true),
        ...(isMonth ? { month: periodNumber } : {})
      })
      console.log('data.data :>> ', data.data);
      setDataCards({ ...dataTest, ...data.data })
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar cupón. Intente de nuevo.')
    }
  }

  const getPreviousMonths = (): {} => {
    let previousMonths = [];
    const current = new Date();

    for (let index = 0; index <= (current.getMonth() - 1); index++) {
      const current = new Date();
      current.setMonth(index);
      previousMonths.push(current.toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + current.toLocaleString('default', { month: 'long' }).slice(1));
    }
    return Object.assign({}, previousMonths);

  }

  const listItems = () => {
    const previousMonths = getPreviousMonths();
    const items: Items = { ...previousMonths, ...dataSelect };
    
    return Object.keys(items).map((key, index) => (
      <MenuItem key={`list_${index}`} value={key}>
        {items[key]}
      </MenuItem>
    )).reverse();
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        {value !== 2 && <>
          <Grid item xs={12} sm={3}>
            <Select value={periodNumber} onChange={handlePeriodSelect} fullWidth>
              {listItems()}
            </Select>
            <Select
              value={storeSelected}
              onChange={handleStoreSelect}
              fullWidth
              defaultValue={0}
              placeholder="Seleccionar tienda"
            >
              <MenuItem value={0}>Todas las tiendas</MenuItem>
              {stores.map(store => (
                <MenuItem value={store.id}>
                  {store.id} - {store.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <DatePicker
              value={startDate}
              defaultValue={null}
              onChange={handleStartDateChange}
              autoOk
              orientation="landscape"
              format={STRINGS.DATE_FORMAT}
              initialFocusedDate={endDate}
              style={{ width: '100%' }}
              label="Desde"
              maxDate={endDate}
              maxDateMessage='La fecha no debe ser posterior a la fecha máxima'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DatePicker
              value={endDate}
              defaultValue={null}
              onChange={handleEndDateChange}
              autoOk
              orientation="landscape"
              format={STRINGS.DATE_FORMAT}
              initialFocusedDate={endDate}
              style={{ width: '100%' }}
              label="Hasta"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button onClick={handleDownload} variant="contained" color="primary" disabled={isRequestingData || startDate > endDate}>
              Generar{' '}
              {isRequestingData && (
                <CircularProgress style={{ color: 'gray', marginLeft: 10 }} thickness={5} size={20} />
              )}
            </Button>
          </Grid>
          {dataCards.period && (<>
            <Grid item xs={12} sm={3}>
              {dataCards.period && <Typography> Comparado con </Typography>}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography> {moment(dataCards.period.dateFromPrevious).local().format(STRINGS.DATE_FORMAT)} </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography> {moment(dataCards.period.dateToPrevious).local().format(STRINGS.DATE_FORMAT)} </Typography>
            </Grid>
          </>)}
        </>}
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="KPIs" {...a11yProps(0)} />
            <Tab label="Beneficios y Rewards" {...a11yProps(1)} />
            <Tab label="Gráficos" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3} item xs={12}>
            <Visits
              data={dataCards.visits}
            />
            <Users
              data={dataCards.users}
            />
            
            <CouponsCard
              dataBenefits={dataCards.benefits}
              dataRewards={dataCards.rewards}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3} item xs={12} >
            {/* <CouponsTopFive startDate={startDate} endDate={endDate} storeId={storeSelected} /> */}
            <RewardsTopFive startDate={startDate} endDate={endDate} storeId={storeSelected} />
            <StoresTopFive startDate={startDate} endDate={endDate} storeId={storeSelected} />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Charts startDate={startDate} endDate={endDate} />
        </TabPanel>
      </Grid>
    </Container >
  )
}

export { Dashboard }
