import { AxiosRequestConfig } from 'axios'

import { UserParams, DownloadParams } from './types'

const downloadUsers = ({
  startDate,
  endDate,
  download,
  downloadVisit,
  statistics = true,
}: UserParams): AxiosRequestConfig => {
  const params = {
    statistics,
    ...(download ? { download } : {}),
    ...(downloadVisit ? { downloadVisit } : {}),
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'users',
    method: 'GET',
    params,
  }
}

const downloadUserPrizes = ({
  id,
  startDate,
  endDate,
  storeId,
  rewardsOnly,
}: DownloadParams): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { start: startDate } : {}),
    ...(endDate ? { end: endDate } : {}),
    storeId,
    rewardsOnly,
  }

  return {
    url: `users/${id}/prizes/filters`,
    method: 'GET',
    params,
  }
}

const downloadUserTest = ({
  startDate,
  endDate
}: UserParams): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
  }

  return {
    url: 'users/user-test',
    method: 'GET',
    params,
  }
}

export { downloadUsers, downloadUserPrizes, downloadUserTest }
