import { Box, LinearProgress, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { useApiCall } from 'hooks'
import { ERRORS } from 'consts'
import { snackbar } from 'components'
import { ReactComponent as Logo } from 'assets/images/main-logo.svg'

import { confirmRegistration, confirmRegistrationForwarding, confirmRegistrationV2 } from './api'
import { ConfirmRegistrationPayload, ConfirmRegistrationResponsePayload } from './types'
import { useStyles } from './styles'

const VERIFICATION = {
  OK: 'Tu cuenta ha sido activada correctamente.\nYa podés iniciar sesión en la app.',
  EXPIRED:
    '¡Oops! Parece que el enlace ha expirado.' +
    '\nNo te preocupes, acabamos de enviarte un enlace nuevo para que puedas terminar tu registro sin problemas.' +
    '\n¡Gracias por tu comprensión!',
}

const ConfirmRegistration: React.FC = () => {
  const classes = useStyles()

  const [message, setMessage] = useState('')
  const [fullName, setFullName] = useState('')

  const [confirmRegistrationApi, isLoading] = useApiCall<
    ConfirmRegistrationPayload,
    ConfirmRegistrationResponsePayload
  >(confirmRegistrationV2)
  const [confirmRegistrationForwardingApi, isLoading2] = useApiCall<
    ConfirmRegistrationPayload,
    ConfirmRegistrationResponsePayload
  >(confirmRegistrationForwarding)

  useEffect(() => {
    const url = window.location.href
    const params = new URL(url).searchParams
    const token = params.get('token') ?? ''

    const confirmRegistrationCall = async () => {
      try {
        const res: ConfirmRegistrationResponsePayload = await confirmRegistrationApi({ token })
        setFullName(res.fullName)
        setMessage(VERIFICATION.OK)
      } catch (e) {
        try {
          await confirmRegistrationForwardingApi({ token })
          setMessage(VERIFICATION.EXPIRED)
        } catch (error) {
          if (error.status === 400 && error.type === 'UserAlreadyVerified') {
            setMessage(VERIFICATION.OK)
          } else {
            snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
          }
        }
      }
    }
    confirmRegistrationCall()
  }, [confirmRegistrationApi, confirmRegistrationForwardingApi])

  return (
    <>
      {(isLoading || isLoading2) && (
        <Box>
          <LinearProgress />
        </Box>
      )}

      <div className={classes.container}>
        <div className={classes.emailContainer}>
          <Logo height={50} width={250} />
          {fullName && (
            <Typography variant="h6" component="h2">
              Hola {fullName},
            </Typography>
          )}
          <Typography>{message}</Typography>
        </div>
      </div>
    </>
  )
}

export { ConfirmRegistration }
