import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, snackbar, UploadButton } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, MEMBERSHIP } from 'consts'

import { createMembership } from '../api'
import { CreateMembershipPayload, CreateMembershipResponse, Membership } from '../types'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  amountVisits: [
    {
      validate: (value: string) => ((value && Number(value) >= 0 && Number(value) < 100) || !value) && Number.isInteger(Number(value)),
      message: 'La cantidad debe ser un número entero mayor a 0 y menor a 100',
    },
  ],
  pushTitle: [
    {
      validate: (title: string) => title.length <= 70,
      message: 'No puede superar los 70 caracteres',
    }
  ],
  pushDescription: [
    {
      validate: (shortTitle: string) => shortTitle.length <= 120,
      message: 'No puede superar los 120 caracteres',
    },
  ],
}

interface CreateMembershipsDialogProps {
  handleClose: () => void
  onDone: (newMembership: Membership) => void
  amountVisitsUsed: number[]
}


const CreateMembershipDialog: React.FC<CreateMembershipsDialogProps> = ({ handleClose, onDone, amountVisitsUsed }) => {

  const [title, setTitle] = useState('')
  const [level, setLevel] = useState(MEMBERSHIP.PERIOD_IN_MONTHS)
  const [amountVisits, setAmountVisits] = useState('')
  const [pushTitle, setPushTitle] = useState('');
  const [pushDescription, setPushDescription] = useState('');
  const [pushMessage, setPushMessage] = useState(false);


  const requiredFields = [title, amountVisits, ...pushMessage ? ['pushTitle', 'pushDescription'] : []]

  const data = { title, amountVisits, pushTitle, pushDescription, pushMessage }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [createMembershipApi, isLoading] = useApiCall<CreateMembershipPayload, CreateMembershipResponse>(
    createMembership,
  )

  const handleSubmit = async () => {
    try {

      /* if (amountVisits && amountVisitsUsed.includes(Number(amountVisits))) {
         snackbar.show('La cantidad de visitas no se puede repetir')
         return
       }*/

      const { membership: newMembership } = await createMembershipApi(data)
      onDone(newMembership)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleChangePushTitle: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushTitle(e.target.value)
  }

  const handleChangepushDescription: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushDescription(e.target.value)
  }

  const handleChangePushMessage: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushMessage(!pushMessage);
  }



  return (
    <Dialog
      title="Nueva Membresia"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Crear membresia"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={amountVisits}
            onChange={e => setAmountVisits(e.target.value)}
            label="Visitas"
            type="number"
            fullWidth
            error={errors.amountVisits.hasError}
            helperText={errors.amountVisits.message}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangePushMessage} checked={pushMessage} />} label="Activar push" />
        </Grid>
        {pushMessage && (
          <>
            <Grid item xs={11}>
              <TextField
                label="Título push"
                value={pushTitle}
                fullWidth
                onChange={handleChangePushTitle}
                disabled={isLoading}
                required
                error={errors.pushTitle.hasError}
                helperText={errors.pushTitle.message}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Descripción push"
                value={pushDescription}
                fullWidth
                onChange={handleChangepushDescription}
                disabled={isLoading}
                required
                error={errors.pushDescription.hasError}
                helperText={errors.pushDescription.message}
              />
            </Grid>
          </>
        )}

      </Grid>
    </Dialog>
  )
}

export { CreateMembershipDialog }
