import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import { snackbar } from 'components'
import { ERRORS, STRINGS } from 'consts'
import { useApi } from 'hooks'
import { GenderEnum } from 'types'
import { COLORS } from 'styles'

import { DefaultTable } from '../components'
import { getUsers } from './api'
import { GetUsersResponse, User } from './types'
import { PollDialog } from './components'

const useStyles = makeStyles({
  link: { color: COLORS.ACCENT },
})

const GenderMap = {
  [GenderEnum.Female]: 'Mujer',
  [GenderEnum.Male]: 'Hombre',
}

const Users: React.FC = () => {
  const [{ data: users, isLoading }] = useApi<GetUsersResponse, User[]>(
    getUsers,
    (responseData: GetUsersResponse) => responseData?.users ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const classes = useStyles()

  const [isPollModalOpen, setIsPollModalOpen] = useState(false)
  const [currentUser, setCurrentUserId] = useState<User|null>(null)

  const handleViewPoll = (e: React.SyntheticEvent, user: User) => {
    e.preventDefault()
    setIsPollModalOpen(true)
    setCurrentUserId(user)
  }

  const handlePollModalClose = () => {
    setIsPollModalOpen(false)
    setCurrentUserId(null)
  }

  const columns = [
    { title: 'ID', field: 'id' },
    { title: 'Email', field: 'email' },
    { title: 'Nombre', field: 'fullname' },
    {
      title: 'Cumpleaños',
      render: (rowData: User) =>
        rowData.birthdate
          ? moment(rowData.birthdate).format(STRINGS.DATE_FORMAT)
          : STRINGS.TABLE_COLUMN_NOT_DEFINED,
      customSort: (a: User, b: User) =>
        !a.birthdate || moment(a.birthdate).isAfter(moment(b.birthdate)) ? 1 : -1,
    },
    {
      title: 'Sexo',
      render: (rowData: User) =>
        rowData.gender ? GenderMap[rowData.gender] : STRINGS.TABLE_COLUMN_NOT_DEFINED,
      customSort: (a: User, b: User) => ((a.gender ?? '') < (b.gender ?? '') ? -1 : 1),
    },
    { title: 'Código QR', field: 'qrHash', sorting: false },
    {
      title: 'Premios obtenidos',
      field: 'obtainedPrizes',
      render: (rowData: User) => rowData.obtainedPrizes || STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    {
      title: 'Premios canjeados',
      field: 'redeemedPrizes',
      render: (rowData: User) => rowData.redeemedPrizes || STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    {
      title: 'User Test',
      render: (row: User) => (
        <a href="/" className={classes.link} onClick={e => handleViewPoll(e, row)}>
          Ver
        </a>
      ),
      sorting: false,
    },
  ]

  return (
    <>
      <DefaultTable title="Usuarios" columns={columns} data={users} isLoading={isLoading} />
      {isPollModalOpen && currentUser && (
        <PollDialog data={currentUser} onCancel={handlePollModalClose} />
      )}
    </>
  )
}

export { Users }
