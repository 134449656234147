// ChartHelpers.tsx

import React from 'react';

interface CustomTickProps {
  x: number;
  y: number;
  payload: any;
}

const CustomTick: React.FC<CustomTickProps> = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={20} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

export { CustomTick };
