import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  quillEditor: {
    marginTop: '15px',
    "& .ql-editor": {
      padding: '6px 0px 3px; !important',
      fontSize: '1rem',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    "& .ql-editor:hover, & .ql-editor:focus": {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87) !important',
      paddingBottom: '2px'
    },
  },
  error: {
    "& .ql-editor, & .ql-editor:hover, & .ql-editor:focus": {
      transform: 'scaleX(1)',
      borderBottom: '2px solid #f44336 !important',
      paddingBottom: '2px'
    },
  }
})

export { useStyles }