import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'left',
    margin: '0.5rem',
    color: theme.palette.text.primary,
    fontSize: 25,
  },
  saveButton: {
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    },
    marginRight: 6
  },
  divider: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    marginBottom: 30
  },
  dividerText: {
    color: theme.palette.primary.main,
    marginBottom: 5,
    marginLeft: 5,
    fontWeight: 500
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  resetButton: {
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    },
    marginBottom: 20
  },
  resetIcon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  rewardType: {
    textAlign: 'left',
    height: '10%',
    alignItems: 'center',
  },
  checkbox: {
    textAlign: 'right',
  },
  iconSection: {
    paddingLeft: '2rem',
    textAlign: 'left',
  },
  iconUploadContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconUploadPreviewContainer: {
    textAlign: 'center',
    border: '1px dashed #ccc',
    margin: '0 20px',
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconPreview: { maxWidth: 30, maxHeight: '100%' },
  // CUSTOM COLOR PICKER





}))

export { useStyles }
