import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DOMPurify from 'dompurify'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Moment } from 'moment'

import { ERRORS, PRIZE } from 'consts'
import { useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getRewards } from './api'
import { DefaultTable } from '../../components'
import { GetRewardsResponse, ParametersDownload, Rewards } from './types'

const useStyles = makeStyles({
  title: {
    color: '#e78923',
  },
  divider: {
    backgroundColor: '#e78923',
  },
})

const columns = [
  { title: 'ID', field: 'prize_id' },
  {
    title: 'Título',
    field: 'prize.title',
    render: (row: { prize: { title: string } }) => (
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.prize.title) }} />
    ),
  },
  { title: 'Cantidad', field: 'count_prize' },
  { title: 'Nivel', field: 'prize.membership.level' },
]

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
  storeId: number
}

const RewardsTopFive: React.FC<propsUsersStatus> = ({ startDate, endDate, storeId }) => {
  const [data, setData] = useState<Rewards[] | []>([])
  const [requestUsersTags, isLoading] = useApiCall<ParametersDownload, GetRewardsResponse>(() =>
    getRewards({ startDate, endDate, storeId }),
  )

  useEffect(() => {
    requestUsersTags({ startDate, endDate, storeId })
      .then(resp => {
        setData(resp.data)
      })
      .catch(error => {
        setData([])
        snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      })
  }, [])

  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" className={classes.title}>
          Beneficios/Rewards Utilizados
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <DefaultTable
          title="Beneficios"
          columns={columns}
          data={data.filter(
            (value: Rewards) => value.prize.reward_type === PRIZE.REWARD_TYPE.ALWAYS_ON,
          )}
          isLoading={isLoading}
          options={{ pageSize: 5, toolbar: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTable
          title="Reward"
          columns={columns}
          data={data.filter(
            (value: Rewards) => value.prize.reward_type !== PRIZE.REWARD_TYPE.ALWAYS_ON,
          )}
          isLoading={isLoading}
          options={{ pageSize: 5, toolbar: true }}
        />
      </Grid>
    </>
  )
}

export { RewardsTopFive }
