import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    background: '#27352e',
    height: '100vh',
  },
  emailContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    color: 'white',
  },
})

export { useStyles }
