import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'styles'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  login: {
    marginLeft: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: COLORS.GREY,
    fontStyle: '400',
  },
  activeLink: {
    textDecoration: 'none',
    color: COLORS.DARK_GREY,
    fontStyle: '400',
  },
}))

export { useStyles }
