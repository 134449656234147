import React from 'react'
import { Button } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

interface UploadButtonProps {
  id: string
  accept: string
  label: React.ReactNode
  options?: Object
  onChange: (file: File, options?: Object) => void
  maxSizeInMB?: number
  withoutStartIcon?: boolean
}

const UploadButton: React.FC<UploadButtonProps> = ({
  id,
  accept,
  label,
  onChange,
  options,
  maxSizeInMB,
  withoutStartIcon,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!e.target.files?.length) return
    const file = e.target.files[0]

    if (maxSizeInMB && file.size > maxSizeInMB * 1024 * 1024) {
      alert(`El tamaño del archivo debe ser menor a ${maxSizeInMB} MB`)
      return
    }

    onChange(file, options)
  }

  return (
    <label htmlFor={id}>
      <input
        accept={accept}
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={handleChange}
      />
      <Button
        variant="contained"
        color="primary"
        component="span"
        startIcon={withoutStartIcon ? undefined : <CloudUploadIcon />}
      >
        {label}
      </Button>
    </label>
  )
}

export { UploadButton }
