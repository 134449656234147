import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Select, MenuItem, InputLabel, InputAdornment } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, UploadButton, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'

import { Prize, UpdatePrizeResponse, UpdatePrizeData } from '../../../../Prizes/types'
import { updatePrize } from '../../../../Prizes/api'
import { useStyles } from './styles'
import { REWARD_TYPE_BY_MEMBERSHIP } from '../constants'
import { PRIZE } from 'consts'
import { EditorInput } from 'features/Main/components'
import { StringUtils } from 'utils'

const rules = {
  title: [
    {
      validate: (title: string) => StringUtils.extractTextFromHTML(title).length <= 70,
      message: 'No puede superar los 70 caracteres',
    },
  ],
  shortTitle: [
    {
      validate: (shortTitle: string) => shortTitle.length <= 22,
      message: 'No puede superar los 22 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  expirationDays: [
    {
      validate: (value: string) => ((value && Number(value) >= 1 || !value) && Number.isInteger(Number(value))),
      message: 'El vencimiento debe ser un número entero mayor a 0',
    },
  ]
}

interface UpdatePrizeDialogProps {
  handleClose: () => void
  prizeToUpdate: Prize
  onDone: (newPrize: Prize) => void
}

const UpdatePrizeDialog: React.FC<UpdatePrizeDialogProps> = ({
  handleClose,
  prizeToUpdate,
  onDone,
}) => {
  const classes = useStyles()

  const [title, setTitle] = useState(prizeToUpdate.title)
  const [shortTitle, setShortTitle] = useState(prizeToUpdate.shortTitle)
  const [icon, setIcon] = useState<File | undefined>(undefined)
  const [iconPreview, setIconPreview] = useState(prizeToUpdate.iconUrl)
  const [additionalText, setAdditionalText] = useState(prizeToUpdate.additionalText ? prizeToUpdate.additionalText : '')
  const [rewardType, setrewardType] = useState(prizeToUpdate.rewardType)
  const [expirationDays, setExpirationDays] = useState<string>(prizeToUpdate.expirationDays ? prizeToUpdate.expirationDays.toString() : '')

  const [updateTagApi, isLoading] = useApiCall<UpdatePrizeData, UpdatePrizeResponse>(updatePrize)

  const requiredFields = [
    'title', 
    'shortTitle', 
    'additionalText',
    ...rewardType === PRIZE.REWARD_TYPE.AUTOMATIC ? ['expirationDays'] : []
   ]

  const fields = { title, shortTitle, additionalText, expirationDays }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const handleIconChange = (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    setIcon(file)
  }

  const handlerewardTypeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setrewardType(event.target.value as string)
  }

  const onUpdateTag = async () => {

    const data = { 
      title, 
      shortTitle, 
      icon, 
      additionalText, 
      rewardType,
      expirationDays 
    }

    const payload = {
      id: prizeToUpdate.id,
      payload: data,
    }
    try {
      const { prize } = await updateTagApi(payload)
      onDone(prize)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar el premio. Intente de nuevo.')
    }
  }



  return (
    <Dialog
      title="Editar premio"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateTag}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <InputLabel id="demo-simple-select-label">Tipo de premio</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rewardType}
            onChange={handlerewardTypeSelect}
            fullWidth
          >
            {REWARD_TYPE_BY_MEMBERSHIP.map(value => (
              <MenuItem value={value.id} key={value.id}>
                {value.description}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={11}>
          <EditorInput
            value={title}
            label="Título premio"
            readOnly={isLoading}
            required={true}
            onChange={setTitle}
            maxLength={70}
            errorText={rules.title[0].message}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Descripcion premio"
            value={shortTitle}
            fullWidth
            onChange={e => setShortTitle(e.target.value)}
            disabled={isLoading}
            required
            error={errors.shortTitle.hasError}
            helperText={errors.shortTitle.message}
            inputProps={{ maxLength: 22 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Texto adicional premio"
            value={additionalText}
            fullWidth
            onChange={e => setAdditionalText(e.target.value)}
            disabled={isLoading}
            required
            error={errors.additionalText.hasError}
            helperText={errors.additionalText.message}
          />
        </Grid>

        {rewardType === PRIZE.REWARD_TYPE.AUTOMATIC && (
        <>
          <Grid item xs={11} sm={5}>
            <TextField
              value={expirationDays}
              type='number'
              fullWidth
              onChange={e => setExpirationDays(e.target.value)}
              disabled={isLoading}
              label="Vencimiento"
              required
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: <InputAdornment position="start">días</InputAdornment>,
              }}
              error={errors.expirationDays.hasError}
              helperText={errors.expirationDays.message}
            />
          </Grid>
        </>)}

        <Grid item xs={11}>
          <div className={classes.iconUploadContainer}>
            <UploadButton
              id="manualReward-icon-upload-button"
              accept=".jpg, .jpeg, .png"
              label="Subir ícono premio"
              onChange={handleIconChange}
            />
          </div>
        </Grid>
        {iconPreview && (
        <>
          <Grid item xs={11}>
            <div className={classes.iconUploadPreviewContainer}>
              <img src={iconPreview} alt="" className={classes.iconPreview} />
            </div>
          </Grid>
        </>)}

      </Grid>
    </Dialog>
  )
}

export { UpdatePrizeDialog }
