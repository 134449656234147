import { Grid, Button, Divider } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ColorPicker } from '../ColorPicker'
import { ImageUploader } from '../ImageUploader'
import { ReactComponent as ResetIcon } from 'assets/images/reset-icon.svg'
import { useStyles } from '../styles'
import { Colors, Style } from '../types'
import { COLORS } from 'styles'
import { STORE_COLORS } from 'styles/storeColors'

interface BackofficeProps {
  style: Style
  setStyle: (style: Style) => void
  setLogoFile: (file: File | undefined) => void
  onSave: () => void
}

const Store: React.FC<BackofficeProps> = ({ style, setStyle, setLogoFile, onSave }) => {
  const classes = useStyles()

  const [primaryColor, setPrimaryColor] = useState<string>('')
  const [primaryContrastColor, setPrimaryContrastColor] = useState<string>('')
  const [secondaryColor, setSecondaryColor] = useState<string>('')
  const [backgroundColor, setBackgroundColor] = useState<string>('')
  const [storeLogoPreview, setLogoPreview] = useState<string>('')

  useEffect(() => {
    const storeColors: Colors = style.storeColors
    setPrimaryColor(storeColors.PRIMARY_MAIN)
    setPrimaryContrastColor(storeColors.PRIMARY_CONTRAST)
    setSecondaryColor(storeColors.SECONDARY_MAIN)
    setBackgroundColor(storeColors.BACKGROUND)
    setLogoPreview(style.storeLogoUrl)
  }, [style])

  const reloadStyle = () => setStyle(style)

  useEffect(() => {
    style.storeColors = {
      PRIMARY_MAIN: primaryColor,
      PRIMARY_CONTRAST: primaryContrastColor,
      SECONDARY_MAIN: secondaryColor,
      BACKGROUND: backgroundColor,
    }
    style.storeLogoUrl = storeLogoPreview
    reloadStyle()
  }, [style.storeColors, style.storeLogoUrl])

  const handlePrimaryColorChange = (color: { hex: string }) => {
    setPrimaryColor(color.hex)
  }
  const handlePrimaryContrastColorChange = (color: { hex: string }) => {
    setPrimaryContrastColor(color.hex)
  }

  const handleSecondaryColorChange = (color: { hex: string }) => setSecondaryColor(color.hex)
  const handleBackgroundChange = (color: { hex: string }) => setBackgroundColor(color.hex)

  const handleLogoChange = (file: File) => {
    setLogoPreview(URL.createObjectURL(file))
    setLogoFile(file)
  }

  const handleLogoDelete = () => {
    setLogoPreview('')
    setLogoFile(undefined)
  }

  const reset = async () => {
    style.storeColors = { ...STORE_COLORS }
    await onSave()
  }

  return (
    <>
      <Grid container alignItems="flex-end" className={classes.dividerContainer}>
        <span className={classes.dividerText}>COLOR</span>
        <Button
          onClick={reset}
          variant="outlined"
          color="primary"
          className={classes.resetButton}
          startIcon={<ResetIcon height={20} width={20} className={classes.resetIcon} />}
        >
          Resetear
        </Button>
      </Grid>
      <Divider className={classes.divider} variant="fullWidth" />
      <Grid container item xs={12} style={{ marginBottom: 30 }}>
        <ColorPicker
          label="Banner y botones"
          color={primaryColor}
          handler={handlePrimaryColorChange}
        />
        <ColorPicker
          label="Fuentes"
          color={primaryContrastColor}
          handler={handlePrimaryContrastColorChange}
        />
        {/*<ColorPicker label="Fuentes" color={secondaryColor} handler={handleSecondaryColorChange} />*/}
        <ColorPicker label="Fondos" color={backgroundColor} handler={handleBackgroundChange} />
      </Grid>

      <Grid container alignItems="flex-end" className={classes.dividerContainer}>
        <span className={classes.dividerText}>IMAGEN</span>
      </Grid>
      <Divider className={classes.divider} variant="fullWidth" />
      <Grid container item xs={12} style={{ marginBottom: 30 }}>
        <ImageUploader
          label="Logo"
          preview={storeLogoPreview}
          onChange={handleLogoChange}
          onDelete={handleLogoDelete}
        />
      </Grid>
    </>
  )
}

export { Store }
