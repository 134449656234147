import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Paper
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  header: {
    textTransform: "uppercase"
  }
}));

interface cardBarProps {
  title:string
  chart:any
}

function CardBar(props:cardBarProps) {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardContent>
          <Typography className={classes.header} color="textPrimary">
            {props.title}
          </Typography>
          <Divider />
          {props.chart}
        </CardContent>
      </Card>
    </>
  );
}

export { CardBar };
