import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'

import { ERRORS } from 'consts'
import { useApi } from 'hooks'
import { snackbar } from 'components'

import { getTags } from './api'
import { DefaultTable } from '../components'
import { GetTagsResponse, Tag } from './types'
import { CreateTagDialog, DeleteTagDialog, UpdateTagDialog } from './components'

import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';

const removeAccents = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const columns = [
  { title: 'ID', field: 'id' },
  {
    title: 'Título',
    field: 'title',
    customFilterAndSearch: (term: string, rowData: Tag) => removeAccents(rowData.title).toLowerCase().indexOf(removeAccents(term).toLowerCase()) != -1
  },
  {
    title: 'Descripción',
    field: 'description',
    customFilterAndSearch: (term: string, rowData: Tag) => removeAccents(rowData.description).toLowerCase().indexOf(removeAccents(term).toLowerCase()) != -1
  },
  {
    title: 'Activo',
    render: (rowData: Tag) => (rowData.active ? 'Sí' : 'No'),
    sorting: false
  },
]

const getTagsResponseGetter = (responseData: GetTagsResponse) => responseData?.tags ?? []

const Tags: React.FC = () => {
  const history = useHistory();

  const [{ data: tags, isLoading }, , setTags] = useApi<GetTagsResponse, Tag[]>(
    getTags,
    getTagsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false)
  const [isDeleteTagDialogOpen, setIsDeleteTagDialogOpen] = useState(false)
  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null)
  const [isUpdateTagDialogOpen, setIsUpdateTagDialogOpen] = useState(false)
  const [tagToUpdate, setTagToUpdate] = useState<Tag | null>(null)

  const [isTagMapOpen, setIsTagMapOpen] = useState(false)
  const [tagToView, settagToView] = useState<Tag | null>(null)

  const handleCreateTagDone = (newTag: Tag) => {
    setTags([
      ...tags
      , newTag]
    )
    setIsCreateTagDialogOpen(false)
  }

  const handleDeleteTagDialogClose = () => {
    setIsDeleteTagDialogOpen(false)
    setTagToDelete(null)
  }

  const handleDeleteTagDone = () => {
    if (!tagToDelete) return
    setTags(tags.filter(tag => tag.id !== tagToDelete.id))
    handleDeleteTagDialogClose()
  }

  const handleUpdateTagDialogClose = () => {
    setIsUpdateTagDialogOpen(false)
    setTagToUpdate(null)
  }

  const handleUpdateTagDone = (updatedTag: Tag) => {
    setTags(
      tags.map(tag => {
        if (tag.id === updatedTag.id) {
          return updatedTag
        }
        return tag
      }),
    )
    handleUpdateTagDialogClose()
  }

  const handleMapDialogClose = () => {
    settagToView(null)
    setIsTagMapOpen(false)
  }

  const actions = [
    /*  {
        icon: 'add',
        tooltip: 'Nueva etiqueta(tag)',
        isFreeAction: true,
        onClick: () => setIsCreateTagDialogOpen(true),
      },*/
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar etiqueta(tag)',
      onClick: (_: React.SyntheticEvent, row: Tag | Tag[]) => {
        setTagToUpdate(row as Tag)
        setIsUpdateTagDialogOpen(true)
      },
    },
    /* {
       icon: 'delete',
       tooltip: `Eliminar`,
       onClick: (_: React.SyntheticEvent, row: Tag | Tag[]) => {
         setTagToDelete(row as Tag)
         setIsDeleteTagDialogOpen(true)
       },
     },*/
  ]

  return (
    <>
      <DefaultTable
        title="Tags"
        columns={columns}
        data={tags}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateTagDialogOpen && (
        <CreateTagDialog
          handleClose={() => setIsCreateTagDialogOpen(false)}
          onDone={handleCreateTagDone}
        />
      )}
      {isDeleteTagDialogOpen && tagToDelete && (
        <DeleteTagDialog
          handleClose={handleDeleteTagDialogClose}
          tagId={tagToDelete.id}
          tagTitle={tagToDelete.title}
          onDone={handleDeleteTagDone}
        />
      )}
      {isUpdateTagDialogOpen && tagToUpdate && (
        <UpdateTagDialog
          handleClose={handleUpdateTagDialogClose}
          tagToUpdate={tagToUpdate}
          onDone={handleUpdateTagDone}
        />
      )}
    </>
  )
}

export { Tags }
