import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    barChart: {
        marginTop: '2em'
    },
})
)

export { useStyles }
