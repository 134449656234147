import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { CardDashboad } from '../components'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { DataUsers } from '../types';
import { COLORS } from 'styles';

const useStyles = makeStyles({
    title: {
        color: COLORS.BASE,
    },
    divider: {
        backgroundColor: COLORS.BASE
    }
});

interface propsUsers {
    data: DataUsers
}

const Users: React.FC<propsUsers> = ({ data }) => {
    const classes = useStyles()

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Usuarios
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.created} description='Cuentas Creadas' value_previous={data.created_previous} isPercent={false} />
            </Grid>
            <Grid item xs={4}>
                {/* <CardDashboad value={data.active} description='Usuarios Activos' />*/}
            </Grid>
        </>
    )
}

export { Users }

