import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer
} from "recharts";
import Grid from '@material-ui/core/Grid'
import { Typography, Box } from '@material-ui/core';
import { Moment } from 'moment'
import { useApiCall } from "hooks";
import { GetDataUserStatusDashboadResponse, DataUserStatusDashboad, ParametersDownload } from "../types";
import { getUserStatus } from "../api";
import { ERRORS } from "consts";
import { snackbar } from "components";
import { useStyles } from "../styles";

const USER_STATUS = {
  'NOT_YET': 'NOT_YET',
  'NEW': 'NEW',
  'WE_MISS_YOU': 'WE_MISS_YOU',
  'RECOVERED': 'RECOVERED',
  'RECURRENT': 'RECURRENT',
  'LOST': 'LOST'

}
interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
}

const UserStatus: React.FC<propsUsersStatus> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [data, setData] = useState<DataUserStatusDashboad[] | []>([])
  const [requestUsersTags, isRequestingUsers] = useApiCall<ParametersDownload, GetDataUserStatusDashboadResponse>(() => getUserStatus({ startDate, endDate }))

  useEffect(() => {
    requestUsersTags({ startDate, endDate }).then(resp => {
      var sortData: Array<DataUserStatusDashboad> = []
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.RECURRENT)!)
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.NEW)!)
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.RECOVERED)!)
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.WE_MISS_YOU)!)
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.LOST)!)
      sortData.push(resp.data.find(obj => obj['code'] === USER_STATUS.NOT_YET)!)
      const sumTags = sortData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
      const dataWithPercent = sortData.map(e => { return { ...e, percent: sumTags ? e.value / sumTags : 0 } })
      setData(dataWithPercent)
    }).catch(error => {
      setData([])
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [startDate, endDate])

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value, height } = props;
    const radius = 20;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius + height / 2}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {' (' + `${(value * 100).toFixed(0)}%` + ')'}
        </text>
      </g>
    );
  };

  return (
    // <Grid container spacing={2} className={classes.chartBody} >
    <Grid container spacing={2} style={{background:"#fff", boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",}}>
      <Grid item xs={12} sm={12} md={8}>
        <ResponsiveContainer width='100%'>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 50,
              left: 20,
              bottom: 5
            }}
            barSize={60}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="value" fill="#e78923" label={{ fill: 'black' }} >
              <LabelList dataKey="percent" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Typography component="div" color="textSecondary" style={{}}>
          <Box fontWeight='fontWeightMedium' display='inline'>Recurrente:</Box> registra al menos 2 escaneos en los últimos 3 meses. No es nuevo.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'> Nuevo:</Box> hizo su primer escaneo en los ultimos 3 meses.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Recuperado:</Box> escaneo una sola vez en los últimos 3 meses. Dejó de ser abandonador o perdido.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Abandonador:</Box> al menos escaneo una vez en su historia y tiene cero escaneos en los últimos 3 meses.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  No yet:</Box> se registró, pero no realizó un escaneo en el local nunca en su historia.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Perdido:</Box> pasaron 6 meses desde su última acción.
        </Typography>
      </Grid>
    {/* </Grid> */}
    </Grid>
  );
}
export { UserStatus }