import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logout: {
    marginLeft: 'auto',
  },
  logo: {
    maxHeight: 50,
    maxWidth: 200
  }
}))

export { useStyles }
