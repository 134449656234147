import { useApiCall } from "hooks";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { getUserByLevel } from './api';
import { DataUsersByLevel, GetDataUserByLevelResponse, ParametersDownload } from "./types";
import { Moment } from 'moment'
import { ERRORS } from "consts";
import { snackbar } from "components";
import { useStyles } from "./styles";
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { CustomPieChart } from "./CustomPieChart";

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
}

const UsersByLevel: React.FC<propsUsersStatus> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [data, setData] = useState<DataUsersByLevel[] | []>([])
  const [requestUsersTags, isRequestingUsers] = useApiCall<ParametersDownload, GetDataUserByLevelResponse>(() => getUserByLevel({ startDate, endDate }))

  useEffect(() => {
    requestUsersTags({ startDate, endDate }).then(resp => {
      setData(resp.data)
    }).catch(error => {
      setData([])
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [startDate, endDate])

  return (
    <Grid container item spacing={2} md={12} style={{ background:"#fff", boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)", paddingTop: 20, paddingBottom: 20 }}>
      {/* <Grid item sm={12}> */}
        <ResponsiveContainer width="100%">
          <CustomPieChart data={data} title='' widthParam={650} cx={270} />
        </ResponsiveContainer>
      {/* </Grid> */}
    </Grid>
  );
}
export { UsersByLevel }