import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'styles'

const useStyles = makeStyles(() => ({
  mainTitleContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  mainTitle: {
    color: COLORS.BASE,
    fontSize: "1.25rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  divider: {
    backgroundColor: COLORS.BASE,
  },
  chartBody: {
    alignItems: "center",
    background: COLORS.WHITE,
    /* border: 1px solid black; */
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
  },
}))

export { useStyles }
