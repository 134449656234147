import React, { useState } from 'react'
import { AppBar, Box, Button, Container, Grid, Tab, Tabs, useTheme } from '@material-ui/core'

import { snackbar, UploadButton } from 'components'
import { ERRORS } from 'consts'
// eslint-disable-next-line import/named
import { useApi, useApiCall } from 'hooks'
import { useStyles } from './styles'
import { createStyle, getStyles } from './api'
import { AppColors, Colors, GetStyleResponse, Style, StyleParams } from './types'
import { Backoffice } from './Backoffice'
import { AppMobile } from './AppMobile'
import { Store } from './Store'
import { APP_COLORS } from 'styles/appColors'
import { STORE_COLORS } from 'styles/storeColors'
import { COLORS } from 'styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: `100%` }}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

const Styles: React.FC = () => {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const [logoImage, setLogoImage] = useState<File | undefined>(undefined)
  const [logoChanged, setLogoChanged] = useState<boolean>(false)
  const [appLogoImage, setAppLogoImage] = useState<File | undefined>(undefined)
  const [appLogoChanged, setAppLogoChanged] = useState<boolean>(false)
  const [homeImage, setHomeImage] = useState<File | undefined>(undefined)
  const [homeChanged, setHomeChanged] = useState<boolean>(false)
  const [storeLogoImage, setStoreLogoImage] = useState<File | undefined>(undefined)
  const [storeLogoChanged, setStoreLogoChanged] = useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const getStyleResponseGetter = (responseData: GetStyleResponse) => {
    const style: Style = responseData.style
    const colors: Colors = style.colors
      ? JSON.parse(style.colors.toString())
      : {
          PRIMARY_MAIN: COLORS.BASE,
          PRIMARY_CONTRAST: COLORS.LIGHT_GREY,
          SECONDARY_MAIN: COLORS.ACCENT,
          BACKGROUND: COLORS.PRIMARY_BACKGROUND,
        }
    const appColors: AppColors = style.appColors
      ? JSON.parse(style.appColors.toString())
      : { ...APP_COLORS }
    const storeColors: Colors = style.storeColors
      ? JSON.parse(style.storeColors.toString())
      : { ...STORE_COLORS }
    return {
      ...style,
      colors,
      appColors,
      storeColors,
    }
  }
  const [{ data: style, isLoading }, , setStyle] = useApi<GetStyleResponse, Style>(
    getStyles,
    getStyleResponseGetter,
    {
      baseData: {
        id: 0,
        colors: {
          PRIMARY_MAIN: '',
          PRIMARY_CONTRAST: '',
          SECONDARY_MAIN: '',
          BACKGROUND: '',
        },
        storeColors: {
          PRIMARY_MAIN: '',
          PRIMARY_CONTRAST: '',
          SECONDARY_MAIN: '',
          BACKGROUND: '',
        },
        appColors: {
          $PRIMARY_BACKGROUND: '',
          $SECONDARY_BACKGROUND: '',
        },
        logoUrl: '',
        homeUrl: '',
        appLogoUrl: '',
        storeLogoUrl: '',
      },
      onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
    },
  )

  const [saveStyle, isSavingStyle] = useApiCall<StyleParams, GetStyleResponse>(createStyle)

  const classes = useStyles()

  const save = async () => {
    try {
      const req = {
        colors: style.colors,
        appColors: style.appColors,
        storeColors: style.storeColors,
        logo: logoImage,
        appLogo: appLogoImage,
        home: homeImage,
        storeLogo: storeLogoImage,
        logoChanged,
        appLogoChanged,
        homeChanged,
        storeLogoChanged,
      }
      await saveStyle(req)
      window.location.reload()
      console.log('reloadeddd')
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar el estilo. Intente de nuevo.')
    }
  }

  const handleLogoChange = (file: File | undefined) => {
    setLogoChanged(true)
    setLogoImage(file)
  }

  const handleAppLogoChange = (file: File | undefined) => {
    setAppLogoChanged(true)
    setAppLogoImage(file)
  }

  const handleHomeChange = (file: File | undefined) => {
    setHomeChanged(true)
    setHomeImage(file)
  }

  const handleStoreLogoChange = (file: File | undefined) => {
    setStoreLogoChanged(true)
    setStoreLogoImage(file)
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Container style={{ marginTop: -24 }}>
      <Grid container spacing={3}>
        <Grid
          container
          alignContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <span className={classes.title}>Estilos App</span>
          <Button
            onClick={save}
            variant="outlined"
            color="primary"
            disabled={isLoading}
            className={classes.saveButton}
          >
            Guardar cambios
          </Button>
        </Grid>

        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.contrastText } }}
          >
            <Tab label="BACKOFFICE" {...a11yProps(0)} />
            <Tab label="APPS" {...a11yProps(1)} />
            <Tab label="STORE" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {!isLoading ? (
            <Backoffice
              style={style}
              setStyle={setStyle}
              setLogoFile={handleLogoChange}
              onSave={save}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!isLoading ? (
            <AppMobile
              style={style}
              setStyle={setStyle}
              setLogoFile={handleAppLogoChange}
              setHomeFile={handleHomeChange}
              onSave={save}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {!isLoading ? (
            <Store
              style={style}
              setStyle={setStyle}
              setLogoFile={handleStoreLogoChange}
              onSave={save}
            />
          ) : null}
        </TabPanel>
      </Grid>
    </Container>
  )
}

export { Styles }
