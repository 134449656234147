import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import RedeemIcon from '@material-ui/icons/Redeem'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { ERRORS } from 'consts'
import { useApi } from 'hooks'
import { snackbar } from 'components'

import { getMemberships } from './api'
import { DefaultTable } from '../components'
import { GetMembershipsResponse, Membership } from './types'
import { CreateMembershipDialog, DeleteMembershipDialog, UpdateMembershipDialog, OrderMembershipPrizesDialog } from './components'
import { MembershipsPrizes } from './Prizes'

const columns = [
  { title: 'ID', field: 'id' },
  { title: 'Título', field: 'title' },
  { title: 'Cantidad de visitas', field: 'amountVisits' },
  { title: 'Nivel', field: 'level' },
]

const getMembershipsResponseGetter = (responseData: GetMembershipsResponse) => responseData?.memberships ?? []

const Memberships: React.FC = () => {
  const [{ data: membships, isLoading }, fetchMemberships, setMemberships] = useApi<GetMembershipsResponse, Membership[]>(
    getMemberships,
    getMembershipsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [membershipToDelete, setMembershipToDelete] = useState<Membership | null>(null)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [membershipToUpdate, setMembershipToUpdate] = useState<Membership | null>(null)
  const [isMembershipsPrizesDialogOpen, setIsMembershipsPrizesDialogOpen] = useState(false)
  const [isMembershipsPrizesOrderDialogOpen, setIsMembershipsOrderPrizesDialogOpen] = useState(false)

  const onCreateDone = (newMembership: Membership) => {
    setMemberships([...membships, newMembership])
    setIsCreateDialogOpen(false)
  }

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
    setMembershipToDelete(null)
  }

  const onDeleteDone = () => {
    if (!membershipToDelete) return
    setMemberships(membships.filter(membership => membership.id !== membershipToDelete.id))
    closeDeleteDialog()
  }

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false)
    setMembershipToUpdate(null)
  }

  const closeMembershipPrizeDialog = () => {
    setIsMembershipsPrizesDialogOpen(false)
    setMembershipToUpdate(null)
  }

  const closeMembershipPrizeOrderDialog = () => {
    setIsMembershipsOrderPrizesDialogOpen(false)
    setMembershipToUpdate(null)
  }

  const onUpdateDone = (newMembership: Membership) => {
    setMemberships(
      membships.map(membership => {
        if (membership.id === newMembership.id) return newMembership
        return membership
      }),
    )
    closeUpdateDialog()
  }

  const onUpdateOrderDone = async () => {
    fetchMemberships()
    closeMembershipPrizeOrderDialog()
  };

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nueva membresia',
      isFreeAction: true,
      onClick: () => setIsCreateDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar membresia',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, membresia: Membership | Membership[]) => {
        setMembershipToUpdate(membresia as Membership)
        setIsUpdateDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar membresia',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, membership: Membership | Membership[]) => {
        setMembershipToDelete(membership as Membership)
        setIsDeleteDialogOpen(true)
      },
    },
    {
      icon: () => <RedeemIcon />,
      tooltip: 'Premios',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, membership: Membership | Membership[]) => {
        setMembershipToUpdate(membership as Membership)
        setIsMembershipsPrizesDialogOpen(true)
      },
    },
    {
      icon: () => <FormatListNumberedIcon />,
      tooltip: 'Ordenar premios',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, membership: Membership | Membership[]) => {
        setMembershipToUpdate(membership as Membership)
        setIsMembershipsOrderPrizesDialogOpen(true)
      },
    },
  ]

  return (
    <>
      <DefaultTable
        title="Membresias"
        columns={columns}
        data={membships}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateDialogOpen && (
        <CreateMembershipDialog
          handleClose={() => setIsCreateDialogOpen(false)}
          onDone={onCreateDone}
          amountVisitsUsed={membships.map(membership => Number(membership.amountVisits))}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteMembershipDialog
          membership={membershipToDelete}
          handleClose={closeDeleteDialog}
          onDone={onDeleteDone}
        />
      )}
      {isUpdateDialogOpen && membershipToUpdate && (
        <UpdateMembershipDialog
          membership={membershipToUpdate}
          handleClose={closeUpdateDialog}
          onDone={onUpdateDone}
          amountVisitsUsed={membships.map(membership => Number(membership.amountVisits))}
        />
      )}
      {isMembershipsPrizesDialogOpen && membershipToUpdate && (
        <MembershipsPrizes
          handleClose={closeMembershipPrizeDialog}
          membershipId={membershipToUpdate.id}
        />
      )}
      {isMembershipsPrizesOrderDialogOpen && membershipToUpdate && (
        <OrderMembershipPrizesDialog
          membership={membershipToUpdate}
          handleClose={closeMembershipPrizeOrderDialog}
          onDone={onUpdateOrderDone}
        />
      )}
    </>
  )
}

export { Memberships }
