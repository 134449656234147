import { useApiCall } from "hooks";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { getUserFrequency } from "../api";
import { DataUserStatusDashboad, GetDataUserStatusDashboadResponse, ParametersDownload } from "../types";
import { Moment } from 'moment'
import { ERRORS } from "consts";
import { snackbar } from "components";
import { useStyles } from "../styles";
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { CustomPieChart } from "./CustomPieChart";

const COLORS = ["#e78923", "#2b5b53", "#26c49e"];
const data = [
  { name: "Cada Tantoo", value: 0 },
  { name: "Frecuente", value: 0 },
  { name: "One Hit", value: 0 }
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index, value
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value} {' (' + `${(percent * 100).toFixed(0)}%` + ')'}
    </text>
  );
};

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
}

const UserFrequency: React.FC<propsUsersStatus> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [data2, setData] = useState<DataUserStatusDashboad[] | []>([])
  const [requestUsersTags, isRequestingUsers] = useApiCall<ParametersDownload, GetDataUserStatusDashboadResponse>(() => getUserFrequency({ startDate, endDate }))

  useEffect(() => {
    requestUsersTags({ startDate, endDate }).then(resp => {
      setData(resp.data.filter(obj => obj.value > 0))
    }).catch(error => {
      setData([])
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [startDate, endDate])

  return (
    <Grid container item spacing={2} md={12} style={{ background:"#fff", boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)", paddingTop: 20, paddingBottom: 20 }}>
      <Grid item sm={12} md={8}>
        <ResponsiveContainer width='100%'>
          <CustomPieChart data={data2} title='' />
        </ResponsiveContainer>
      </Grid>
      <Grid item sm={12} md={4}>
        <Typography component="div" color="textSecondary" style={{}}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Fan:</Box> Fan: registra 5 o más escaneos en los últimos 3 meses
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Frecuente:</Box>   registra entre 2 y 4 escaneos en los últimos 3 meses
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  One Hit:</Box>  registra 1 escaneo en los últimos 3 meses
        </Typography>
      </Grid>
    </Grid>
  );
}
export { UserFrequency }