import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getManualRewards, getUserTestTagData } from './api'
import { DefaultTable } from '../components'
import {
  GetManualRewardsResponse,
  ManualReward,
  GetUserTestTagResponse,
  GetUserTestTagData,
} from './types'
import {
  CreateManualRewardDialog,
  DeleteManualRewardDialog,
  UpdateManualRewardDialog,
  ProcessManualRewardDialog,
} from './components'
import { getActiveTags, getTotalUsersWithAllTags } from '../Tags/api'
import {
  GetTagsResponse,
  GetTotalUsersWithAllTagsPayload,
  GetTotalUsersWithAllTagsResponse,
  Tag,
} from '../Tags/types'
import calculateUserTestByTags from '../Helpers/userTestHelper'

const onRenderTags = (item: any) => {
  if (!item.tags || !item.tags.length) return ''
  const tags = item.tags.map((t: any) => t.title)
  return tags.join(', ')
}

const columns = [
  { title: 'Título', field: 'title' },
  { title: 'Descripción', field: 'shortTitle' },
  { title: 'Texto adicional', field: 'additionalText' },
  {
    title: 'Icono',
    render: (rowData: ManualReward) => <img src={rowData.iconUrl} alt="" style={{ width: 20 }} />,
    sorting: false,
  },
  {
    title: 'Sale con push?',
    render: (rowData: ManualReward) => (rowData.pushMessage ? 'Sí' : 'No'),
    sorting: false,
  },
  { title: 'Titulo push', field: 'pushTitle' },
  { title: 'Bajada push', field: 'pushDownload' },
  {
    title: 'Tags',
    render: (rowData: ManualReward) => onRenderTags(rowData),
  },
  {
    title: 'Tags requeridos',
    render: (rowData: ManualReward) => (rowData.allTagsAreRequired ? 'Sí' : 'No'),
    sorting: false,
  },
  {
    title: 'Vencimiento',
    render: (rowData: ManualReward) => (
      <table>
        <tbody>
          <tr>
            <td
              style={{
                color: moment().isAfter(rowData.expiration) ? 'red' : 'black',
              }}
            >
              {moment(rowData.expiration).format(STRINGS.DATE_FORMAT)}
            </td>
          </tr>
        </tbody>
      </table>
    ),
    customSort: (a: ManualReward, b: ManualReward) =>
      !a.expiration || moment(a.expiration).isAfter(moment(b.expiration)) ? 1 : -1,
  },
  {
    title: 'Fecha ejecución',
    render: (rowData: ManualReward) =>
      rowData.executedAt ? moment(rowData.executedAt).format('YYYY-MM-DD hh:mm') : '-',
  },
  {
    title: 'Activo',
    render: (rowData: ManualReward) => (rowData.active ? 'Sí' : 'No'),
    sorting: false,
  },
]

const getManualRewardsResponseGetter = (responseData: GetManualRewardsResponse) =>
  responseData?.manualRewards ?? []
const getTagsResponseGetter = (responseData: GetTagsResponse) => responseData?.tags ?? []
const getUserTestTagResponseGetter = (responseData: GetUserTestTagResponse) =>
  responseData?.userTestTagData ?? []

const ManualRewards: React.FC = () => {
  const history = useHistory()

  const [{ data: manualRewards, isLoading }, , setManualRewards] = useApi<
    GetManualRewardsResponse,
    ManualReward[]
  >(getManualRewards, getManualRewardsResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })
  const [{ data: tags }, , setTags] = useApi<GetTagsResponse, Tag[]>(
    getActiveTags,
    getTagsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [{ data: userTestTagData }, , setUserTestTagData] = useApi<
    GetUserTestTagResponse,
    GetUserTestTagData[]
  >(getUserTestTagData, getUserTestTagResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })

  const [isCreateManualRewardDialogOpen, setIsCreateManualRewardDialogOpen] = useState(false)

  const [isDeleteManualRewardDialogOpen, setIsDeleteManualRewardDialogOpen] = useState(false)
  const [manualRewardToDelete, setManualRewardToDelete] = useState<ManualReward | null>(null)

  const [isUpdateManualRewardDialogOpen, setIsUpdateManualRewardDialogOpen] = useState(false)
  const [manualRewardToUpdate, setManualRewardToUpdate] = useState<ManualReward | null>(null)

  const [isProcessManualRewardDialogOpen, setIsProcessManualRewardDialogOpen] = useState(false)
  const [manualRewardToProcess, setManualRewardToProcess] = useState<ManualReward | null>(null)

  const [getTotalUsersWithAllTagsApi] = useApiCall<
    GetTotalUsersWithAllTagsPayload,
    GetTotalUsersWithAllTagsResponse
  >(getTotalUsersWithAllTags)

  const handleCalculateUserTestByTags = async (
    tags: any[],
    allTagsAreRequired = false,
  ): Promise<number> => {
    if (!allTagsAreRequired) return calculateUserTestByTags(userTestTagData, tags)

    if (!tags.length) return 0
    return getTotalUsersWithAllTagsApi({ tagIds: tags.map(el => el.value) }).then(e => e.total)
  }

  const handleCreateManualRewardDone = (newManualReward: ManualReward) => {
    setManualRewards([newManualReward, ...manualRewards])
    setIsCreateManualRewardDialogOpen(false)
  }

  const handleDeleteManualRewardDialogClose = () => {
    setIsDeleteManualRewardDialogOpen(false)
    setManualRewardToDelete(null)
  }

  const handleProcessManualRewardDialogClose = () => {
    setIsProcessManualRewardDialogOpen(false)
    setManualRewardToProcess(null)
    snackbar.show('Reward manual procesado')
  }

  const handleDeleteManualRewardDone = () => {
    if (!manualRewardToDelete) return
    setManualRewards(
      manualRewards.filter(manualReward => manualReward.id !== manualRewardToDelete.id),
    )
    handleDeleteManualRewardDialogClose()
  }

  const handleProcessManualRewardDone = (procesedManualReward: ManualReward) => {
    handleUpdateManualRewardDone(procesedManualReward)
    if (!manualRewardToProcess) return
    handleProcessManualRewardDialogClose()
  }

  const handleUpdateManualRewardDialogClose = () => {
    setIsUpdateManualRewardDialogOpen(false)
    setManualRewardToUpdate(null)
  }

  const handleUpdateManualRewardDone = (updatedManualReward: ManualReward) => {
    setManualRewards(
      manualRewards.map(manualReward => {
        if (manualReward.id === updatedManualReward.id) {
          return updatedManualReward
        }
        return manualReward
      }),
    )
    handleUpdateManualRewardDialogClose()
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nueva etiqueta(manualReward)',
      isFreeAction: true,
      onClick: () => setIsCreateManualRewardDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar etiqueta(manualReward)',
      onClick: (_: React.SyntheticEvent, row: ManualReward | ManualReward[]) => {
        setManualRewardToUpdate(row as ManualReward)
        setIsUpdateManualRewardDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: `Eliminar`,
      onClick: (_: React.SyntheticEvent, row: ManualReward | ManualReward[]) => {
        setManualRewardToDelete(row as ManualReward)
        setIsDeleteManualRewardDialogOpen(true)
      },
    },
    (row: ManualReward) => ({
      icon: () => <CheckCircleOutlineIcon />,
      tooltip: 'Procesar',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, row: ManualReward | ManualReward[]) => {
        setManualRewardToProcess(row as ManualReward)
        setIsProcessManualRewardDialogOpen(true)
      },
      hidden:
        !row.active ||
        !row.expiration ||
        moment().isAfter(row.expiration) ||
        row.executedAt !== null,
    }),
  ]

  return (
    <>
      <DefaultTable
        title="Rewards manuales"
        columns={columns}
        data={manualRewards}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateManualRewardDialogOpen && tags && (
        <CreateManualRewardDialog
          handleClose={() => setIsCreateManualRewardDialogOpen(false)}
          onDone={handleCreateManualRewardDone}
          calcUsersByTags={handleCalculateUserTestByTags}
          tags={tags}
        />
      )}
      {isDeleteManualRewardDialogOpen && manualRewardToDelete && (
        <DeleteManualRewardDialog
          handleClose={handleDeleteManualRewardDialogClose}
          manualRewardId={manualRewardToDelete.id}
          manualRewardTitle={manualRewardToDelete.title}
          onDone={handleDeleteManualRewardDone}
        />
      )}
      {isProcessManualRewardDialogOpen && manualRewardToProcess && (
        <ProcessManualRewardDialog
          handleClose={handleProcessManualRewardDialogClose}
          manualRewardId={manualRewardToProcess.id}
          manualRewardTitle={manualRewardToProcess.title}
          onDone={handleProcessManualRewardDone}
        />
      )}
      {isUpdateManualRewardDialogOpen && manualRewardToUpdate && (
        <UpdateManualRewardDialog
          handleClose={handleUpdateManualRewardDialogClose}
          manualRewardToUpdate={manualRewardToUpdate}
          onDone={handleUpdateManualRewardDone}
          calcUsersByTags={handleCalculateUserTestByTags}
          tags={tags}
        />
      )}
    </>
  )
}

export { ManualRewards }
