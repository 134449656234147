import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: theme.palette.background.default,
    minHeight: '100vh',
  },
  toolbar: theme.mixins.toolbar,
}))

export { useStyles }
