import React from 'react'

import { useApiCall } from 'hooks'
import { Dialog, snackbar } from 'components'
import { ERRORS } from 'consts'

import { Membership } from '../types'
import { deleteMembership } from '../api'

interface Props {
  handleClose: () => void
  membership: Membership | null
  onDone: () => void
}

const DeleteMembershipDialog: React.FC<Props> = ({ handleClose, membership, onDone }) => {
  const [deleteMembershipApi, isLoading] = useApiCall<number, void>(deleteMembership)

  const onDelete = async () => {
    if (!membership) return
    try {
      await deleteMembershipApi(membership.id)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar la membresia ${membership?.title}?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      onAccept={onDelete}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
    />
  )
}

export { DeleteMembershipDialog }
