import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'

import { MultiSelect } from "react-multi-select-component";
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker } from '@material-ui/pickers'
import Divider from '@material-ui/core/Divider';

import { STRINGS } from 'consts'
import { useApiCall, useCheckFormErrors } from 'hooks'
import { Dialog, UploadButton, snackbar } from 'components'
import { ManualReward, UpdateManualRewardResponse, UpdateManualRewardData } from '../../types'
import { updateManualReward } from '../../api'
import { Tag } from './../../../Tags/types'
import { useStyles } from './styles'

const rules = {
	title: [
		{
			validate: (title: string) => title.length <= 25,
			message: 'No puede superar los 25 caracteres',
		},
	],
	shortTitle: [
		{
			validate: (shortTitle: string) => shortTitle.length <= 22,
			message: 'No puede superar los 22 caracteres',
		},
	],
	additionalText: [
		{
			validate: (value: string) => (value && value.length <= 100) || !value.length,
			message: 'No puede superar los 100 caracteres',
		},
	]
}

interface UpdateManualRewardDialogProps {
	handleClose: () => void
	manualRewardToUpdate: ManualReward
	onDone: (newManualReward: ManualReward) => void
	tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const UpdateManualRewardDialog: React.FC<UpdateManualRewardDialogProps> = ({
	handleClose,
	onDone,
	manualRewardToUpdate,
	tags,
	calcUsersByTags
}) => {
	const classes = useStyles()

	const [title, setTitle] = useState(manualRewardToUpdate.title)
	const [shortTitle, setShortTitle] = useState(manualRewardToUpdate.shortTitle)
	const [icon, setIcon] = useState<File | undefined>(undefined)
	const [iconPreview, setIconPreview] = useState(manualRewardToUpdate.iconUrl)
	const [image, setImage] = useState<File | undefined>(undefined)
	const [imagePreview, setImagePreview] = useState(manualRewardToUpdate.imageUrl)
	const [pushMessage, setPushMessage] = useState(manualRewardToUpdate.pushMessage ? true : false);
	const [pushTitle, setPushTitle] = useState(manualRewardToUpdate.pushTitle)
	const [pushDescription, setPushDescription] = useState(manualRewardToUpdate.pushDescription)
	const [active, setActive] = useState(manualRewardToUpdate.active ? true : false);
	const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [usersByTags, setUsersByTags] = useState(0)
	const [expiration, setExpiration] = useState<Moment | null>(moment(manualRewardToUpdate.expiration))
	const [additionalText, setAdditionalText] = useState(manualRewardToUpdate.additionalText ? manualRewardToUpdate.additionalText : '')
	const firstExpirationDate = moment().add(1, 'days').format('YYYY-MM-DD hh:mm')
	const [allTagsAreRequired, setAllTagsAreRequired] = useState(manualRewardToUpdate.allTagsAreRequired)

  const [updateManualRewardApi, isLoading] = useApiCall<UpdateManualRewardData, UpdateManualRewardResponse>(updateManualReward)
  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

	useEffect(() => {
		if (!manualRewardToUpdate || !manualRewardToUpdate.tags) return
    
    const localTagsSelected = manualRewardToUpdate.tags.map(el => ({ label: el.title, value: el.id }))
    setTagsSelected(localTagsSelected)

    const getUsersByTags = async () => {
      return await calcUsersByTags(localTagsSelected, allTagsAreRequired);
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
	}, []);

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))    
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)    
    setUsersByTags(total)
  }
  
	const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setTitle(e.target.value)
	}

	const handleShortTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setShortTitle(e.target.value)
	}

	const handleIconChange = (file: File) => {
		setIconPreview(URL.createObjectURL(file))
		setIcon(file)
	}

	const handleImageChange = (file: File) => {
		setImagePreview(URL.createObjectURL(file))
		setImage(file)
	}

	const handleChangePushMessage: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushMessage(!pushMessage);
		if (!pushMessage) {
			setPushTitle('')
			setPushDescription('')
		}
	}

	const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
		setActive(!active);
	}

	const handleChangePushTitle: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushTitle(e.target.value)
	}

	const handleChangepushDescription: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushDescription(e.target.value)
	}

	const handleDateChange = (newDate: Moment | null) => {
		setExpiration(newDate)
	}

	const handleAdditionalTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setAdditionalText(e.target.value)
	}

	const fields = { title, shortTitle, additionalText }
	const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules)
	const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

	const onUpdateManualReward = async () => {
		const manualReward = {
			title,
			shortTitle,
			icon,
			image,
			pushMessage,
			pushTitle,
			pushDescription,
			active,
			tagsSelected,
			expiration: expiration?.toISOString(),
			additionalText,
			allTagsAreRequired
		}

		const payload = {
			id: manualRewardToUpdate.id,
			shortTitle: manualRewardToUpdate.shortTitle,
			payload: manualReward,
			pushMessage: pushMessage,
			active: active,
		}
		try {
			const { manualReward: updatedManualReward } = await updateManualRewardApi(payload)
			onDone(updatedManualReward)
		} catch (err) {
			console.error(err)
			snackbar.show('No se pudo editar premio. Intente de nuevo.')
		}
	}

	return (
		<Dialog
			title="Editar reward manual"
			isOpen
			showActions
			onCancel={handleClose}
			isLoading={isLoading}
			okButtonText="Editar"
			okButtonProps={{ disabled: isSubmitDisabled }}
			onAccept={onUpdateManualReward}
		>
			<Grid container spacing={3}>
				<Grid item xs={11}>
					<TextField
						label="Título"
						value={title}
						fullWidth
						onChange={handleTitleChange}
						disabled={isLoading}
						required
						error={errors.title.hasError}
						helperText={errors.title.message}
						inputProps={{ maxLength: 25 }}
					/>
				</Grid>
				<Grid item xs={11}>
					<TextField
						label="Descripcion"
						value={shortTitle}
						fullWidth
						onChange={handleShortTitleChange}
						disabled={isLoading}
						required
						error={errors.shortTitle.hasError}
						helperText={errors.shortTitle.message}
						inputProps={{ maxLength: 60 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Texto adicional"
						value={additionalText}
						fullWidth
						onChange={handleAdditionalTextChange}
						disabled={isLoading}
						required
						error={errors.additionalText.hasError}
						helperText={errors.additionalText.message}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<div className={classes.iconUploadContainer}>
						<UploadButton
							id="manualReward-icon-upload-button"
							accept="image/*"
							label="Subir ícono"
							onChange={handleIconChange}
						/>
						<div className={classes.iconUploadPreviewContainer}>
							<img src={iconPreview} alt="" className={classes.iconPreview} />
						</div>
					</div>
				</Grid>
				<Grid item xs={11}><Divider variant="middle" /></Grid>
				<Grid item xs={11}>
					<InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
					<FormControl className={classes.fullWidth}>
						<MultiSelect
							options={tagsForSelect}
							value={tagsSelected}
							onChange={handleSetTags}
							labelledBy={"Seleccionar Tags"}
							hasSelectAll={false}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={<Checkbox onChange={handleAllTagsAreRequiredChange}
							checked={allTagsAreRequired}
						/>}
						label="Sólo usuarios con todos los tags seleccionados"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<DatePicker
						value={expiration}
						onChange={handleDateChange}
						autoOk
						disablePast
						format={STRINGS.DATE_FORMAT}
						minDate={firstExpirationDate}
						initialFocusedDate={firstExpirationDate}
						disabled={isLoading}
						style={{ width: '100%' }}
						minDateMessage="La fecha de vencimiento no puede ser anterior a hoy"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<div className={classes.iconUploadContainer}>
						<UploadButton
							id="manualReward-image-upload-button"
							accept="image/*"
							label="Subir imagen popup"
							onChange={handleImageChange}
							maxSizeInMB={2}
						/>
						<div className={classes.iconUploadPreviewContainer}>
							<img src={imagePreview} alt="" className={classes.iconPreview} />
						</div>
					</div>
				</Grid>
				<Grid item xs={11}>
					<FormControlLabel control={<Checkbox onChange={handleChangePushMessage} checked={pushMessage} />} label="Sale con push?" />
				</Grid>
				{pushMessage && (
					<>
						<Grid item xs={11}>
							<TextField
								label="Titulo push"
								value={pushTitle}
								fullWidth
								onChange={handleChangePushTitle}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={11}>
							<TextField
								label="Descripción push"
								value={pushDescription}
								fullWidth
								onChange={handleChangepushDescription}
								disabled={isLoading}
							/>
						</Grid>
					</>
				)}
				<Grid item xs={11}>
					<FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active} />} label="Activar Reward" />
				</Grid>
				<Grid item xs={11}>
					<InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export { UpdateManualRewardDialog }
