import { AxiosRequestConfig } from 'axios'

import { ConfirmRegistrationPayload } from './types'

const confirmRegistrationV2 = (payload: ConfirmRegistrationPayload): AxiosRequestConfig => ({
  url: `/v2/auth/verification?token=${payload.token}`,
  method: 'GET',
})

const confirmRegistration = (payload: ConfirmRegistrationPayload): AxiosRequestConfig => ({
  url: `/accounts/verification?token=${payload.token}`,
  method: 'GET',
})

const confirmRegistrationForwarding = (
  payload: ConfirmRegistrationPayload,
): AxiosRequestConfig => ({
  url: `/accounts/verification/forwarding`,
  method: 'POST',
  data: {
    ...payload,
  },
})

export { confirmRegistration, confirmRegistrationV2, confirmRegistrationForwarding }
