import React, { useState } from "react";
import "./styles.css";
import { Grid } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UserPoll } from "../../types";
import { Dialog } from 'components'

interface UserPollOrderDialogProps {
  userPolls: UserPoll[]
  handleClose: () => void
  onUpdateOrder: (order: number[]) => void
}

const UserPollOrderDialog: React.FC<UserPollOrderDialogProps> = ({ userPolls, handleClose, onUpdateOrder }) => {
//export default function OrderUserPollDialog() {

  const [userPollList, setUserPollList] = useState(userPolls);

  const handleUpdateOrder = () => {
    onUpdateOrder(userPollList.map(el => el.id))
  }

  // Function to update list on drop
  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...userPollList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setUserPollList(updatedList);
  };

  const grid = 8;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
  
    // change background colour if dragging
    background: isDragging ? "#e78923" : "grey",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
  });

  return (
    <Dialog
      title={`Ordenamiento`}
      isOpen
      showActions
      onCancel={handleClose}
      //isLoading={isLoading}
      okButtonText="Guardar"
      //okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleUpdateOrder}
    >
      <Grid container spacing={3} justify="center" alignItems="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {userPollList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id+'_'+item.question} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.question}
                      </div>
                    )}
                  </Draggable>
                  
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Dialog>
  );
}

export { UserPollOrderDialog }