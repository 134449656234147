import { Grid, Card, CardContent, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChromePicker, Color, ColorChangeHandler } from 'react-color'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 400,
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    margin: 10,
  },
  cardContent: {
    paddingBottom: '0 !important',
  },
  colorPickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  colorPickerLabel: {
    fontSize: 18,
    color: '#757575',
    marginBottom: theme.spacing(1),
  },
  customChromePicker: {
    boxShadow: 'none !important',
  },
}))

interface CustomColorPickerProps {
  label: string
  color: Color
  handler: ColorChangeHandler
}

const ColorPicker: React.FC<CustomColorPickerProps> = ({ label, color, handler }) => {
  const classes = useStyles()

  return (
    <>
      <Grid>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.colorPickerContainer}>
              <div className={classes.colorPickerLabel}>{label}</div>
              <ChromePicker
                color={color}
                onChangeComplete={handler}
                className={classes.customChromePicker}
                disableAlpha={true}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export { ColorPicker }
