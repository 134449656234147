import React, { useState, useEffect } from 'react'
import { Grid, TextField, FormControl } from '@material-ui/core'
import { Dialog } from 'components'
import { constants } from "../../constansts";
import { useApiCall } from 'hooks'
import { UserPoll, UserPollDetail } from '../../types'
import { getUserPollDetailsWithStatistics } from '../../api'
import { PieChartComponent } from './../Charts/PieChart'
import { CardBar } from './../Charts/CardBar'
import { useStyles } from './styles'

interface StatisticsUserPollDialog {
  userPoll: UserPoll
  handleClose: () => void
}

const StatisticsUserPollDialog: React.FC<StatisticsUserPollDialog> = ({ userPoll, handleClose }) => {
  const classes = useStyles();
  const [userPollDetails, setUserPollDetails] = useState(userPoll.detail);

  const [getUserPollDetailsApi, isLoading] = useApiCall<UserPoll, UserPollDetail[]>(
    getUserPollDetailsWithStatistics,
  )

  useEffect(() => {
		if (userPoll) {
      const fetchData = async () => {
        const userPollDetailsData = await getUserPollDetailsApi(userPoll)

        if (userPollDetailsData) setUserPollDetails(userPollDetailsData)
      }    
      fetchData()
		}
	}, []);

  return (
    <Dialog
      title={`Estadísticas ${constants.TEST_NAME}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      readOnly={true}
      contentStyle={{ minWidth: 400 }}
      style={{ minWidth: '40%' }}
    >
      <Grid container spacing={4}>
      {userPollDetails && userPollDetails.map((el, index) => (
        <>
          <Grid item xs={8}>
            <TextField
              id={`${el.id}_re_${index}`}
              label="Respuesta"
              value={el.answer}
              fullWidth              
              inputProps={
                { readOnly: true, }
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <TextField
                id={`${el.id}_us_${index}`}
                label="Usuarios"
                value={el.totalUsers}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={
                  { readOnly: true, }
                }
              />
            </FormControl>
          </Grid>
        </>
        ))}
      </Grid>
      <Grid item xs={12} className={classes.barChart}>
        <CardBar title="Activity" chart={<PieChartComponent data={userPollDetails}/>} />
      </Grid>
    </Dialog>
  )
}

export { StatisticsUserPollDialog }