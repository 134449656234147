import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { Moment } from 'moment'
import { useApiCall } from 'hooks'
import {
  getAssetsByPeriodByStateVariation,
  getAssetsByPeriodByUsersByLevelVariation,
  getAssetsByPeriodByFrecuencyVariation,
  getAssetsByPeriodByFrequencyAverage,
  getAssetsByPeriodByStatusAverage,
} from './api'
import { useStyles } from './styles'
import { DataEntry, assetsByPeriodResponse } from './types'
import { CHART_NAMES } from 'consts'
import { UserTest, UserStatus, UserFrequency, AreaChartComponent, AverageAssetByPeriod, UsersByLevel, UsersByLevelVariation, ActiveVsInactiveUser } from './Components'

interface propsCharts {
  startDate: Moment
  endDate: Moment
}

type TagGroupBase = Record<string, number>

type TagGroup = {
  period: string
} & TagGroupBase

interface assetsByPeriodVariationResponse {
  data: TagGroup[]
}

const Charts: React.FC<propsCharts> = ({ startDate, endDate }) => {
  const classes = useStyles()
  const [assetsByPeriodStateVariation, setAssetsByPeriodStateVariation] = useState<TagGroup[] | []>(
    [],
  )
  const [assetsByUsersByLevelVariation, setAssetsByUsersByLevelVariation] = useState<
    TagGroup[] | []
  >([])
  const [assetsByPeriodByFrecuencyVariation, setAssetsByPeriodByFrecuencyVariation] = useState<
    TagGroup[] | []
  >([])
  const [assetsByPeriodByStatusAverage, setAssetsByPeriodByStatusAverage] = useState<
    DataEntry[] | []
  >([])
  const [assetsByPeriodByFrecuencyAverage, setAssetsByPeriodByFrecuencyAverage] = useState<
    DataEntry[] | []
  >([])

  const [fetchAssetsByPeriodByState, isloadingAbPSV] = useApiCall<
    void,
    assetsByPeriodVariationResponse
  >(getAssetsByPeriodByStateVariation)

  const [fetchAssetsByUsersByLevelVariation, isloadingAbUbLV] = useApiCall<
    void,
    assetsByPeriodVariationResponse
  >(getAssetsByPeriodByUsersByLevelVariation)

  const [fetchAssetsByPeriodByFrecuency, isloadingAbPFV] = useApiCall<
    void,
    assetsByPeriodVariationResponse
  >(getAssetsByPeriodByFrecuencyVariation)
  const [fetchAssetsByPeriodByStatusAverage, isloadingAbPSA] = useApiCall<
    void,
    assetsByPeriodResponse
  >(getAssetsByPeriodByStatusAverage)
  const [fetchAssetsByPeriodByFrequencyAverage, isloadingAbPFA] = useApiCall<
    void,
    assetsByPeriodResponse
  >(getAssetsByPeriodByFrequencyAverage)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assetsByPeriodStateVariationApiResponse = await fetchAssetsByPeriodByState()
        setAssetsByPeriodStateVariation(assetsByPeriodStateVariationApiResponse.data)

        const assetsByUsersByLevelVariationResponse = await fetchAssetsByUsersByLevelVariation()
        setAssetsByUsersByLevelVariation(assetsByUsersByLevelVariationResponse.data)

        const assetsByPeriodByFrecuencyVariationApiResponse = await fetchAssetsByPeriodByFrecuency()
        setAssetsByPeriodByFrecuencyVariation(assetsByPeriodByFrecuencyVariationApiResponse.data)

        const assetsByPeriodByStatusAverageApiResponse = await fetchAssetsByPeriodByStatusAverage()
        setAssetsByPeriodByStatusAverage(assetsByPeriodByStatusAverageApiResponse.data)

        const assetsByPeriodByFrequencyAverageApiResponse = await fetchAssetsByPeriodByFrequencyAverage()
        setAssetsByPeriodByFrecuencyAverage(assetsByPeriodByFrequencyAverageApiResponse.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (
      !isloadingAbPSV &&
      !isloadingAbPFV &&
      !isloadingAbPSA &&
      !isloadingAbPFA &&
      !isloadingAbUbLV
    ) {
      fetchData()
    }
  }, [
    fetchAssetsByPeriodByState,
    fetchAssetsByUsersByLevelVariation,
    fetchAssetsByPeriodByFrecuency,
    fetchAssetsByPeriodByStatusAverage,
    fetchAssetsByPeriodByFrequencyAverage,
  ])

  const makeTitle = (title: string) => (
    <Grid item xs={12} className={`${classes.mainTitleContainer} ${classes.mainTitle}`}>
      {title}
      <Divider className={classes.divider} />
    </Grid>
  )

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.USERS_BY_LEVEL)}
        <UsersByLevel startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.BY_STATUS)}
        <UserStatus startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.ACTIVE_USERS)}
        <ActiveVsInactiveUser startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.STATUS_TEST)}
        <UserTest startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.BY_FRECUENCY)}
        <UserFrequency startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.USERS_BY_LEVEL_VARIATION)}
        {assetsByUsersByLevelVariation && (
          <Grid item sm={12} className={classes.chartBody}>
            <UsersByLevelVariation data={assetsByUsersByLevelVariation} />
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_STATE_VARIATION)}
        {assetsByPeriodStateVariation && (
          <Grid item sm={12} className={classes.chartBody}>
            <AreaChartComponent data={assetsByPeriodStateVariation} />
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_FREQUENCY_VARIATION)}
        {assetsByPeriodByFrecuencyVariation && (
          <Grid item sm={12} className={classes.chartBody}>
            <AreaChartComponent data={assetsByPeriodByFrecuencyVariation} />
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_STATUS_AVERAGE)}
        {assetsByPeriodByStatusAverage && (
          <Grid item sm={12} className={classes.chartBody}>
            <AverageAssetByPeriod inputData={assetsByPeriodByStatusAverage} />
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_FREQUENCY_AVERAGE)}
        {assetsByPeriodByFrecuencyAverage && (
          <Grid item sm={12} className={classes.chartBody}>
            <AverageAssetByPeriod inputData={assetsByPeriodByFrecuencyAverage} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
export { Charts }
