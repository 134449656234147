import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import React, { useState } from 'react'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import { useStyles } from './styles'
import { DeltaStatic } from 'quill'
import 'react-quill/dist/quill.bubble.css'

const TOOLBAR_OPTIONS = ['bold']

interface EditorInputProps {
  value?: string
  label?: string
  required?: boolean
  readOnly?: boolean
  maxLength?: number
  errorText?: string
  onChange?: (changes: string) => void
}

const EditorInput: React.FC<EditorInputProps> = (props: EditorInputProps) => {
  const classes = useStyles()

  const [value, setValue] = useState<string>(props.value || '')
  const [maxLength] = useState(props.maxLength || -1)
  const [errorText] = useState(props.errorText || '')
  const [hasErrors, setHasErrors] = useState(false)

  const handleEditorContentChange = (
    content: string,
    delta: DeltaStatic,
    source: any,
    editor: UnprivilegedEditor,
  ) => {
    setValue(content)
    if (props.onChange) props.onChange(content)
    setHasErrors(editor.getText().trim().length > maxLength)
  }

  return (
    <FormControl fullWidth>
      {props.label && (
        <InputLabel required={props.required} shrink error={hasErrors}>
          {props.label}
        </InputLabel>
      )}
      <ReactQuill
        modules={{ toolbar: TOOLBAR_OPTIONS }}
        theme="bubble"
        value={value}
        onChange={handleEditorContentChange}
        readOnly={props.readOnly}
        className={classes.quillEditor + (hasErrors ? ' ' + classes.error : '')}
      />
      {hasErrors && errorText && <FormHelperText error={hasErrors}>{errorText}</FormHelperText>}
    </FormControl>
  )
}

export { EditorInput }
