import React from 'react'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { deletePrize } from '../../../Prizes/api'

interface DeletePrizeDialogProps {
  handleClose: () => void
  onDone: () => void
  prizeId: number
  prizeTitle: string
}

const DeletePrizeDialog: React.FC<DeletePrizeDialogProps> = ({
  handleClose,
  prizeId,
  prizeTitle,
  onDone,
}) => {
  const [deletePrizeApi, isLoading] = useApiCall<number, void>(deletePrize)

  const onDeletePrize = async () => {
    try {
      await deletePrizeApi(prizeId)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo eliminar el premio. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar el premio "${prizeTitle}"?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onDeletePrize}
    />
  )
}

export { DeletePrizeDialog }
