const CHART_NAMES = {
  'STATUS_TEST': 'Status Test',
  'BY_STATUS': 'Análisis de Usuarios por Estado',
  'BY_FRECUENCY': 'Análisis de Usuarios por Frecuencia',
  'USERS_BY_LEVEL': 'Análisis de Usuarios por Nivel',
  'ACTIVE_USERS': 'Análisis de Usuarios Activos',
  'CURRENT_USUERS_ANALISIS': 'Análisis de Usuarios (Actual)',
  'USERS_BY_LEVEL_VARIATION': 'Variación Mensual de Activos por Nivel',
  'ASSETS_BY_PERIOD_STATE_VARIATION': 'Variación Mensual de Activos por Estado',
  'ASSETS_BY_PERIOD_FREQUENCY_VARIATION': 'Variación Mensual de Activos por Frecuencia',
  'ASSETS_BY_PERIOD_STATUS_AVERAGE': 'Promedio Mensual de Activos por Estado',
  'ASSETS_BY_PERIOD_FREQUENCY_AVERAGE': 'Promedio Mensual de Activos por Frecuencia',
}

const CHART_COLORS = [
  '#CD6155',
  '#AF7AC5',
  '#5499C7',
  '#48C9B0',
  '#58D68D',
  '#F4D03F',
  '#EB984E',
  '#AAB7B8',
  '#5D6D7E',
  '#1D8348'
];
  
export { CHART_NAMES, CHART_COLORS }
  