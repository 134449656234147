const COLORS = {
  BASE: '#27352e',
  PRIMARY_BACKGROUND: 'rgb(245, 246, 248)',
  // SECONDARY_BACKGROUND: '#FFEEE1',
  ACCENT: 'rgb(39,94,86)',
  WHITE: '#fff',
  LIGHT_GREY: '#efefef',
  GREY: 'rgba(0, 0, 0, 0.54)',
  DARK_GREY: '#2E2E2E'
}

export { COLORS }
