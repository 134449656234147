import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  iconUploadContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  multiselectTitle: {
    marginBottom: '4px',
  },
  iconPreview: { 
    maxWidth: 50, 
    maxHeight: '100%',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    textAlign: 'center', // Añadido para centrar el contenido
  },
  draggable: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius || 4,
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette ? theme.palette.action.hover : '#e0e0e0',
    },
  },
}));

export { useStyles };
