import { AxiosRequestConfig } from 'axios'
import { StyleParams } from './types'

const getStyles = (): AxiosRequestConfig => ({
  url: '/styles',
  method: 'GET',
})

const createStyle = (style: StyleParams): AxiosRequestConfig => {
  const {
    colors,
    appColors,
    storeColors,
    logo,
    appLogo,
    home,
    storeLogo,
    logoChanged,
    appLogoChanged,
    homeChanged,
    storeLogoChanged,
  } = style

  const payload = new FormData()
  if (colors) payload.append('colors', JSON.stringify(colors).toString())
  if (appColors) payload.append('appColors', JSON.stringify(appColors).toString())
  if (storeColors) payload.append('storeColors', JSON.stringify(storeColors).toString())
  if (logo) payload.append('logo', logo)
  if (appLogo) payload.append('appLogo', appLogo)
  if (home) payload.append('home', home)
  if (storeLogo) payload.append('storeLogo', storeLogo)
  payload.append('logoChanged', logoChanged ? 'true' : 'false')
  payload.append('appLogoChanged', appLogoChanged ? 'true' : 'false')
  payload.append('homeChanged', homeChanged ? 'true' : 'false')
  payload.append('storeLogoChanged', storeLogoChanged ? 'true' : 'false')

  return {
    url: '/styles',
    method: 'POST',
    data: payload,
  }
}

export { getStyles, createStyle }
